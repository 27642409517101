import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpOauth } from '../common/service/http-oauth';


@Injectable()
export class PulicHolidaySettingService {
  serverPath = environment.FWA_URL;
  constructor(
    private httpOauth: HttpOauth,
  ) {

  }

  getPublicHolidayList(searchBody): Observable<any> {
    const url = `${this.serverPath}/epatrol/publicHolidaySetting/all`;
    const params = { params: searchBody };
    return this.httpOauth.get(url, {}, false, params);
  }
  getYearsList(searchBody): Observable<any> {
    const url = `${this.serverPath}/epatrol/publicHolidaySetting/year/all`;
    const params = { params: searchBody };
    return this.httpOauth.get(url, {}, false, params);
  }

  savePublicHoliday(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/publicHolidaySetting`;
    return this.httpOauth.post(url, params);
  }

  updatePublicHoliday(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/publicHolidaySetting/${params.id}`;
    return this.httpOauth.put(url,params);
  }

  getDuplicatePublicHolidayDate(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/publicHolidaySetting/duplicateDate`;
    return this.httpOauth.post(url, params);
  }

  deletePublicHolidaySetting(id): Observable<any> {
    const url = `${this.serverPath}/epatrol/publicHolidaySetting/${id}`;
    return this.httpOauth.delete(url);
  }
}