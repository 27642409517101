import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
  showDialog: boolean = false;
  messageArray = new Array(3);

  currentIndex: number = 0;
  neverShow: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  showSlide(index) {
    const slider = document.querySelector('.slider');
    const slides = document.querySelectorAll('.slide');
    console.log('index', index)
    if (index < 0) {
      this.currentIndex = slides.length - 1;
    } else if (index >= slides.length) {
      this.currentIndex = 0;
    } else {
      this.currentIndex = index;
    }

    const translateValue = -this.currentIndex * 100;
    slider['style'].transform = `translateX(${translateValue}%  + 16px)`;
  }

  checkedChange(e){
    this.neverShow = e.checked;
  }

  dialogConfirm() {
    this.showDialog = false;
  }

  clickLeft() {
    this.showSlide(this.currentIndex - 1);
  }

  clickRight() {
    this.showSlide(this.currentIndex + 1);
  }

  changeCurrentIndex(i: number) {
    this.currentIndex = i;
    this.showSlide(i)
  }
}
