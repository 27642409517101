import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpOauth } from '../common/service/http-oauth';
import { map } from 'rxjs/operators';


@Injectable()
export class DownloadReportService {
  serverPath = environment.FWA_URL;
  constructor(
    private httpOauth: HttpOauth,    
  ) {}

  buildingTree(siteId,lang) {
    const url = `${this.serverPath}/epatrol/export/building/tree/${siteId}?lang=${lang}`;
    return this.httpOauth.get(url,{},false);
  }

  epatrolFile(key,name) {
    const encodeId = encodeURIComponent(key);
    const url = `${this.serverPath}/epatrol/file?key=${encodeId}`;
    return this.httpOauth.get(url,{},false,{ responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${name}`);
      })
    );
  }

  epatrolPdfFile(key) {
    const encodeId = encodeURIComponent(key);
    const url = `${this.serverPath}/epatrol/file?key=${encodeId}`;
    return this.httpOauth.get(url,{},false,{ responseType: 'blob' })
  }

  siteAclAll(params) {
    const url = `${this.serverPath}/epatrol/site/acl/all`;
    return this.httpOauth.get(url,{},false,params);
  }

  exportMultiBachByBuildingDir(params,name) {
    const url = `${this.serverPath}/epatrol/export/download/exportMultiBachByBuildingDir`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${name}.zip`);
      })
    );
  }
  exportMultiBachByRouteDir(params,name) {
    const url = `${this.serverPath}/epatrol/export/download/exportMultiBachByRouteDir`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${name}.zip`);
      })
    );
  }

  exportMultiBachByYearDir(params,name) {
    const url = `${this.serverPath}/epatrol/export/download/exportMultiBachByYearDir`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${name}.zip`);
      })
    );
  }

  exportMultiBachByMonthDir(params,name) {
    const url = `${this.serverPath}/epatrol/export/download/exportMultiBachByMonthDir`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${name}.zip`);
      })
    );
  }

  exportMultiBachByDateDir(params,name) {
    const url = `${this.serverPath}/epatrol/export/download/exportMultiBachByDateDir`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${name}.zip`);
      })
    );
  }

  exportMultiBachByDetailId(params,name,type) {
    const url = `${this.serverPath}/epatrol/export/download/exportMultiBachByDetailId`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        if(type=='pdf'){
          this.httpOauth.downloadFile(res, `${name}.pdf`);
        }else{
          this.httpOauth.downloadFile(res, `${name}.xlsx`);
        }
      })
    );
  }

  downloadMultiattAchment(params,name) {
    const url = `${this.serverPath}/epatrol/export/download/multi/attachment`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${name}.zip`);
      })
    );
  }

  buildingTreeSiteId(siteId) {
    const url = `${this.serverPath}/epatrol/export/building/${siteId}`;
    return this.httpOauth.get(url,{},false);
  }

  getRouteList(buildingId,status) {
    const url = `${this.serverPath}/epatrol/export/route/${buildingId}?status=${status}`;
    return this.httpOauth.get(url,{},false);
  }

  getRouteDate(buildingRouteId,params) {
    const url = `${this.serverPath}/epatrol/export/date/${buildingRouteId}`;
    return this.httpOauth.get(url,{},false,params);
  }

  exportDetail(params) {
    const url = `${this.serverPath}/epatrol/export/detail`;
    return this.httpOauth.post(url,params);
  }
}
