export function getNameAbbr(userName) {
    const reg26 = /^[a-zA-Z]$/;
    const cnReg26 = /^[\u4e00-\u9fa5]/;
    let initials = '';
    if (userName) {
        const nameArr = userName.trim().split(" ");
        const cnAddrArray = cnReg26.exec(nameArr[0]);
        if (cnAddrArray) {
            return cnAddrArray[0];
        } else {
            nameArr.forEach((ele, index) => {
                if (index < 3) {
                    const abbr = ele.slice(0, 1);
                    if (reg26.test(abbr)) {
                        initials += abbr;
                    }
                }
            });
        }
    }
    return initials;
}

export function getAttachmentType(attchType: string) {
    const type = attchType.toLowerCase();
    if (type == 'pdf') {
        return 'pdf';
    } else if (type == 'jpeg' || type == 'jpg' || type == 'png') {
        return 'image';
    } else {
        return 'doc';
    }
}

export function setLocalStorage(name, value) {
    localStorage.setItem(name, value);
}
  
export function getLocalStorage(cname) {
    const result = localStorage.getItem(cname);
    return result && result !== 'undefined' ? result : null;
}

export function getAvatarBgColor(id: number) {
    const colors = ['#C7A9A6', '#8C95B0', '#9BB089', '#CFC295', '#AF9AB9', '#91BCB7', '#779FBC'];
    return colors[Math.abs(Number(id)) % 7];
}
  
export function getAvatarBgColorByName(name: string) {
    let name2Id = 0;
    if (name) {
        for (const char of name) {
        name2Id += char.charCodeAt(0);
        }
    }
    return getAvatarBgColor(name2Id);
}

export function getFilterEle(filters, key: string) {
  return filters.filter((ele) => ele.key == key)[0];
}
