import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CoreModule } from './core/core.module';
// App
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// Components
import { ComponentModule } from './common/components/component.module';
import { ShellComponent } from './shell/shell.component';
// Service
import { UserService } from './core/services/user.service';
import { AuthGuard } from './core/services/auth-guard.service';
import { HttpOauth } from './common/service/http-oauth';
import { RoleManagementService } from './service/role-management.service';
import { UserManagementService } from './service/user-management.service';
import { MoBuildingsService } from './service/mo-buildings.service';
import { EPatrolSettingsService } from './service/ePatrol-settings.service';
import { CommonDeviceManagementService } from './service/common-device-management.service';
import { PatrolRecordsService } from './service/patrol-records.service';
import { DownloadReportService } from './service/download-report.service';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from './core/basic-error-handler';
import { DayjsService } from './common/service/dayjs.service';
import { MatIconRegistry } from '@angular/material/icon';
import { loadSvgResources } from './common/util/svg-icon-reg';
import { PulicHolidaySettingService } from './service/public-holiday-setting.service';

export function initializeUserConfig(userService: UserService) {
  return () => {
    return userService.runInitialLoginSequence();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ShellComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ComponentModule,
    HttpClientModule,
    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    })
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUserConfig,
      deps: [UserService, Injector],
      multi: true,
    },
    AuthGuard,
    HttpOauth,
    RoleManagementService,
    UserManagementService,
    DayjsService,
    MoBuildingsService,
    EPatrolSettingsService,
    CommonDeviceManagementService,
    PatrolRecordsService,
    DownloadReportService,
    PulicHolidaySettingService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    ir: MatIconRegistry,
    ds: DomSanitizer
  ) {
    loadSvgResources(ir, ds);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
