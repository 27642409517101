import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// material component
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

// translate
import { TranslateModule } from '@ngx-translate/core';

// project component
import { AvatarComponent } from './avatar/avatar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { GeneralErrorComponent } from './general-error/general-error.component';
import { DashBoardCardBaseComponent } from './dashboard-card-base/dashboard-card-base.component';
import { GeneralDialogComponent } from './general-dialog/general-dialog.component';
import { AttachmentPreviewComponent } from './attachment-preview/attachment-preview.component';
import { SkeletonTextComponent } from './skeleton-text/skeleton-text.component';
import { MessageDialogComponent } from 'src/app/pages/system-message/component/message-dialog/message-dialog.component';
import { GroupSelectionList } from './group-selection-list/group-selection-list.component';

// oursky component
import {
  SwireFrontlineUiModule,
  SfuiDialogModule,
  SfuiChipModule,
  SfuiChipListFieldModule,
  SfuiTextFieldModule,
  SfuiGroupSelectionListModule,
  SfuiUploadAttachmentModule,
  SfuiDateTimeFieldModule,
  SfuiCardModule,
  SfuiButtonModule,
  SfuiSideMenuModule,
  SfuiAttachmentPreviewModule,
  SfuiTimelineModule,
  SfuiLineGraphModule,
  SfuiDoughnutChartModule,
  SfuiBarChartModule,
  SfuiDraggableTableModule,
  SfuiExpandableListModule,
  SfuiDraggableItemModule,
  SfuiCheckListFieldModule,
  SfuiDropdownModule
} from 'swire-frontline-ui';

// directive
import { OperationCodeDirective } from '../directive/operation.directive';
import { AutoFocusDirective } from '../directive/auto-focus.directive';
// pipes
import { AppPropertyI18NPipe } from '../pipes/property-i18n.pipe';
import { ArrowHeaderItem } from './arrow-header/arrow-header-item.component';
import { ArrowHeader } from './arrow-header/arrow-header.component';
import { OperationPipe } from '../pipes/operation.pipe';
import { FormatDatePipe } from '../pipes/format-date.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AddCheckListComponent } from 'src/app/pages/ePatrol-settings/components/add-checklist/add-checklist.component';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    CustomButtonComponent,
    AvatarComponent,
    GeneralErrorComponent,
    AttachmentPreviewComponent,
    ArrowHeader,
    ArrowHeaderItem,
    SkeletonTextComponent,
    MessageDialogComponent,
    GroupSelectionList,
    AddCheckListComponent,
    // directive
    OperationCodeDirective,
    AutoFocusDirective,
    DashBoardCardBaseComponent,
    GeneralDialogComponent,
    // pipes
    AppPropertyI18NPipe,
    OperationPipe,
    FormatDatePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    // translate
    TranslateModule,
    // material component
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatProgressBarModule,
    MatChipsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatAutocompleteModule,
    // oursky component
    SwireFrontlineUiModule,
    SfuiChipListFieldModule,
    SfuiTextFieldModule ,
    SfuiDialogModule,
    SfuiGroupSelectionListModule,
    SfuiUploadAttachmentModule,
    SfuiChipModule,
    SfuiDateTimeFieldModule,
    SfuiCardModule,
    SfuiButtonModule,
    SfuiSideMenuModule,
    SfuiAttachmentPreviewModule,
    SfuiTimelineModule,
    SfuiLineGraphModule,
    SfuiDoughnutChartModule,
    SfuiBarChartModule,
    SfuiDraggableTableModule,
    SfuiExpandableListModule,
    SfuiDraggableItemModule,
    SfuiCheckListFieldModule,
    SfuiDropdownModule
  ],
  providers: [
    OperationPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    //translate
    TranslateModule,
    // material component
    MatMenuModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule,
    MatDialogModule,
    MatCheckboxModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatProgressBarModule,
    MatChipsModule,
    MatTooltipModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatAutocompleteModule,
    // project component
    BreadcrumbComponent,
    CustomButtonComponent,
    AvatarComponent,
    GeneralErrorComponent,
    GroupSelectionList,
    AddCheckListComponent,
    DashBoardCardBaseComponent,
    GeneralDialogComponent,
    AttachmentPreviewComponent,
    ArrowHeader,
    ArrowHeaderItem,
    SkeletonTextComponent,
    MessageDialogComponent,
    // oursky component
    SwireFrontlineUiModule,
    SfuiChipListFieldModule,
    SfuiTextFieldModule,
    SfuiDialogModule,
    SfuiGroupSelectionListModule,
    SfuiUploadAttachmentModule,
    SfuiChipModule,
    SfuiDateTimeFieldModule,
    SfuiCardModule,
    SfuiButtonModule,
    SfuiSideMenuModule,
    SfuiAttachmentPreviewModule,
    SfuiTimelineModule,
    SfuiLineGraphModule,
    SfuiDoughnutChartModule,
    SfuiBarChartModule,
    SfuiDraggableTableModule,
    SfuiDraggableItemModule,
    SfuiCheckListFieldModule,
    SfuiDropdownModule,
    // directive
    OperationCodeDirective,
    AutoFocusDirective,
    // pipes
    AppPropertyI18NPipe,
    OperationPipe,
    FormatDatePipe
  ]
})
export class ComponentModule {}
