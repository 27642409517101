import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, NEVER, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { Router } from '@angular/router';
import { SafeUrl } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { GeneralDialogService } from "./general-dialog.service";
import { ErrorCode } from 'src/app/common/constants/enums';

@Injectable()

export class HttpOauth {
  oAuthEnable = environment.FWA_URL;

  public errorEventEmitter: EventEmitter<string> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private router: Router,
    private generalDialogService: GeneralDialogService,
    private translate: TranslateService,
  ) { }

  AppendHeader(headers: HttpHeaders): HttpHeaders {
    let _headers;

    if (!headers) {
      _headers = new HttpHeaders();
    } else {
      _headers = headers;
    }

    _headers = _headers
      .append("Authorization", `Bearer ${localStorage.getItem("access_token")}`);

    return _headers;
  }

  get(url: string, options: any = {}, showError: boolean = false, params = {}): Observable<any> {
    if (url.includes(this.oAuthEnable)) {
      return this.http.get<any>(url, {
        ...params,
        headers: this.AppendHeader(options.headers)
      }).pipe(catchError((error) => this.handleError(error, showError)));
    } else {
      return this.http.get<any>(url, options);
    }
  }

  post(url: string, body?: any, options: any = {}, showError: boolean = false, params = {}): Observable<any> {
    if (url.includes(this.oAuthEnable)) {
      return this.http.post(url, body, {
        ...params,
        headers: this.AppendHeader(options.headers)
      }).pipe(catchError((error) => this.handleError(error, showError)));
    } else {
      return this.http.post(url, body, options);
    }
  }

  put(url: string, body: any, options: any = {}, showError: boolean = false): Observable<any> {
    if (url.includes(this.oAuthEnable)) {
      options.headers = this.AppendHeader(options.headers);

      return this.http.put(url, body, options).pipe(catchError((error) => this.handleError(error, showError)));
    } else {
      return this.http.put(url, body, options);
    }
  }

  delete(url: string, options: any = {}, showError: boolean = false): Observable<any> {
    if (url.includes(this.oAuthEnable)) {
      options.headers = this.AppendHeader(options.headers);

      return this.http.delete(url, options).pipe(catchError((error) => this.handleError(error, showError)));
    } else {
      return this.http.delete(url, options);
    }
  }

  downloadFile(fileBinary: Blob, fileName: string, mimeType?: string) {
    const blob = new Blob([fileBinary], { type: `${mimeType}` });
    console.log('Download res', blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);
  }

  downloadFileByUrl(url: SafeUrl | string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    //SafeUrl src = url["changingThisBreaksApplicationSecurity"]
    a.href = url["changingThisBreaksApplicationSecurity"] || url;
    a.download = fileName;
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
    }, 0);
  }

  handleError(error, showError: boolean = false): Observable<any> {
    if (!showError) {
      if (error && error.status == 401 && error.error && error.error.hasOwnProperty("isAuthenticated")) {
        this.errorEventEmitter.emit(error.error.message);
        return NEVER;
      } else if (error && error.status >= 400) {
        // alert common error for different error code
        this.showErrorInfo(error);
      }
    }
    return throwError(error);
  }

  showErrorInfo(error) {
    const customHandleErr = ['10006', ErrorCode.ADUserDoesNotExist];
    if (!error.error || error.error.code === "10005") {
      this.router.navigate(['/admin/generalError/' + '10005']);
    } else if (error.error.code === "10000") {
      this.router.navigate(['/login']);
    } else if (error.error.code === "10001") {
      this.router.navigate(['/admin/generalError/' + '10001'])
    } else if (error.error.code === "10002") {
      if(this.router.url === '/admin/dashboard'){
        return;
      }
      this.router.navigate(['/admin/generalError/' + '10002'])
    } else if(error.error.code === "50004"){
      this.router.navigate(['/generalError/' + '50004'])
    } else if (customHandleErr.includes(error.error.code)) {
      return throwError(error);
    } else {
      this.generalDialogService.show({
        width: 280,
        header: {
          src: '../../../../assets/icon/general-error/system-error.svg',
          class: 'session-expiring-img',
          text: error.error.message,
        },
        content: {
          class: 'error-dialog-content-text',
          text: this.translate.instant('COMMON.SERVER_ERROR_CONTENT', {
            code: error.error.code ? error.error.code : '0',
          }),
          errorCode: error.error.code,
          traceId: error.error.traceId,
          timestamp: error.error.timestamp,
        },
        primaryBtn: {
          first: true,
          class: "error-primary-500-btn",
          text: 'COMMON.GOT_IT',
          onClick: () => {
            this.generalDialogService.close();
          }
        }
      });
    }
  }
}
