<button
  mat-button
  class="btn"
  [operationCode]="operationCode"
  [ngClass]="btnClass"
  (click)="onClick()"
  [disabled]="disable"
>
  <img
    alt=""
    class="icon"
    [ngClass]="iconClass"
    *ngIf="iconImg"
    src="../../assets/icon/common/{{ iconImg }}.svg"
  />
  <span *ngIf="iconFont" class="material-icons-outlined font-icon" [ngClass]="iconClass">{{iconFont}}</span>
  {{ btnText | translate }}
  <mat-spinner
    diameter="16"
    strokeWidth="3"
    *ngIf="loading"
    [ngClass]="loadingClass"
    class="mat-spinner-color"
  ></mat-spinner>
</button>
