// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  identity_provider: 'frontline-workplace-dev-saml',
  //OAUTH_URL: 'https://frontline-workplace-hk-dev-1v1.auth.ap-southeast-1.amazoncognito.com',
  OAUTH_URL: 'https://api-dev.swireproperties.com/frontline-workplace-web-api/cognito',
  OAUTH_CLIENT_ID: '1p3a76dudm7alsi4v6q030tgah',
  APP_BASE_URL: 'https://dev-frontline-admin.swireproperties.com',
  // FWA_URL: "https://api-dev.swireproperties.com/frontline-workplace-api",
  FWA_URL: "https://api-dev.swireproperties.com/frontline-workplace-web-api",
  PMS_WORK_HK_URL: "http://sphkappd12:1080/_dev/pmsHK/WO",
  PMS_WORK_CN_URL: "http://sphkappd12:1080/_dev/pmsCN/WO",
  version: 'hk-dev-1-1518'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
