import { Injectable, Injector } from '@angular/core';
import { OAuthService, OAuthStorage, TokenResponse } from 'angular-oauth2-oidc';
import { Observable, ReplaySubject, BehaviorSubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { map, distinctUntilChanged, filter, catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { v4 as uuidv4 } from 'uuid';
import { GuestProfile } from '../../core/models/guest/member-profile';
import { HttpOauth } from '../../common/service/http-oauth';
import {setCookie, getCookie} from '../../common/util';
import { GeneralDialogService } from '../../common/service/general-dialog.service';
import { setLocalStorage } from 'src/app/common/util/tool';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUserSubject = new BehaviorSubject<any>({} as any);

  public isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  public permissionSubject = new BehaviorSubject<any>({} as any);
  public permission$ = this.permissionSubject.asObservable().pipe(distinctUntilChanged());

  public userRoleSubject = new BehaviorSubject<any>({} as any);
  public userRole$ = this.userRoleSubject.asObservable().pipe(distinctUntilChanged());

  public systemAdminSubject = new BehaviorSubject<any>({} as any);
  public systemAdmin$ = this.systemAdminSubject.asObservable().pipe(distinctUntilChanged());

  PERMISSIONS: string[] = [];
  USER_ACCESS: any[] = [];
  ACTIVE_CODE_DESC: any[] = [];
  ACTIVE_TAG_DESC: any[] = [];
  ALL_MODULE: any[] = [];
  IS_TIMEOUT_TO_LOGIN: boolean = false;
  DEFECT_SAFETY_CODE = [
    "DEFECT_TYPE_SAFETY",
    "DEFECT_TYPE_NON_SAFETY",
    "DEFECT_TYPE_NON_SAFETY.PRC"
  ];

  // private refreshProgramme$: Subscription = null;

  get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }

  get currentUserInfo(): GuestProfile {
    return this.currentUserSubject.value;
  }

  get currentUserRole(): string {
    return this.userRoleSubject.value;
  }

  get permissions(): string[] {
    return this.PERMISSIONS;
  }

  set permissions(permissions: string[]) {
    this.PERMISSIONS = permissions;
  }

  get userAccess() {
    return this.USER_ACCESS;
  }

  set userAccess(userAccess: any) {
    this.USER_ACCESS = userAccess;
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  get activeCodeDesc(): any {
    return this.ACTIVE_CODE_DESC;
  }

  set activeCodeDesc(activeCodeDesc: any) {
    this.ACTIVE_CODE_DESC = activeCodeDesc;
  }

  get allModule(): any {
    return this.ALL_MODULE;
  }

  set allModule(allModule: any) {
    this.ALL_MODULE = allModule;
  }

  get activeTagDesc(): any {
    return this.ACTIVE_TAG_DESC;
  }

  set activeTagDesc(activeTagDesc: any) {
    this.ACTIVE_TAG_DESC = activeTagDesc;
  }

  get isTimeoutToLogin(): boolean {
    return this.IS_TIMEOUT_TO_LOGIN;
  }

  set isTimeoutToLogin(isTimeoutToLogin: boolean) {
    this.IS_TIMEOUT_TO_LOGIN = isTimeoutToLogin;
  }

  constructor(
    private oauthService: OAuthService,
    private injector: Injector,
    private oAuthStorage: OAuthStorage,
    private dialog: MatDialog,
    private httpOauth: HttpOauth,
    private translate: TranslateService,
    private generalDialogService: GeneralDialogService
  ) {
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events.pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type))).subscribe(() => this.login());

  }

  async runInitialLoginSequence(): Promise<boolean> {
    if (!this.oauthService.hasValidAccessToken()) {
      if (this.isRootAddress()) {
        return false;
      } else {
        if (this.isAdminAddress()) {
          this.navigateToLoginPage(location.href.replace(location.origin, ''));
          return false;
        }
      }
    }
    try {
      // 0. LOAD CONFIG & SET SESSION ID
      this.initAuthSessionId();
      await this.getClientId();
      await this.oauthService.tryLogin();
      await this.verifyToken();
      await this.loadUserAccess();
      await this.setCodeDescriptions();
      await this.setAllModule();
      await this.setTagDesc();
      await this.redirectTo();
      this.upsertLoginTime();
      return true;
    } catch (error) {
      console.log(error);
    }
  }

  async redirectTo() {
    const state = decodeURIComponent(this.oauthService.state);
    if (state && state.trim() !== '') {
      this.router.navigateByUrl(state);
    }
  }

  async setTagDesc() {
    try {
      const descriptions = await this.getAllTagDescriptions();
      if (descriptions) {
        this.activeTagDesc = this.formatDescs(descriptions, 'tagDescriptions');
      }
    } catch (result) {
      console.log(result);
      throw new Error();
    }
  }

  async setAllModule() {
    const module = await this.getAllModules();
    if (module) {
      this.allModule = module;
    }
  }

  async verifyToken() {
    if (this.oauthService.hasValidAccessToken()) {
      this.handleExpireTime()
      if (Object.keys(this.currentUserInfo).length === 0) {
        await this.loadAppUserProfile();
      }
    }
    this.refreshToken();
  }

  async loadUserAccess() {
    try {
      const userAccess = await this.getUserAccess();
      if (userAccess) {
        // set global language after get user langPreference
        const lanPre = userAccess.langPreference;
        const curLan = lanPre ? lanPre : getCookie('cLan');
        this.translate.use(curLan);
        this.changeFontFamily(curLan);
        if (lanPre) {
          setCookie('cLan', lanPre);
        } else {
          this.updateUserLangPreference({ "langPreference": curLan });
        }
        this.permissions = userAccess.operationCodeList;
      }
    } catch (error) {
      console.log(error);
      throw new Error();
    }
  }


  async setCodeDescriptions() {
    // get all code descriptions
    try {
      const descriptions = await this.getAllCodeDescriptions();
      if (descriptions) {
        this.activeCodeDesc = this.formatDescs(descriptions, 'codeDescriptions');
      }
    } catch (result) {
      console.log(result);
      throw new Error();
    }
  }

  isRootAddress() {
    return (location.pathname === '/' && location.search === '') ||
     (location.pathname === '/login');
  }
  
  isAdminAddress() {
    return location.href.includes(environment.APP_BASE_URL + '/admin/');
  }

  /**
   * handle timeout
   */
  handleExpireTime() {
    const expireTime = this.oAuthStorage.getItem('expires_at');
    if (expireTime) {
      const expireConfirmTime = Number(expireTime) - (15 * 60 * 1000);
      const now = new Date();
      const timeoutSec = expireConfirmTime - now.getTime();

      setTimeout(() => {
        this.generalDialogService.show({
          header: {
            src: '../../../../assets/icon/common/session_expiring.svg',
            class: 'session-expiring-img',
            text: 'COMMON.SESSION_EXPIRING',
          },
          content: {
            class: 'session-expiring-dialog-content',
            text: 'COMMON.SESSION_EXPIRING_CONTENT',
          },
          primaryBtn: {
            first: true,
            text: 'COMMON.GOT_IT',
            onClick: () => {
              const clickedTime = new Date();
              const clickedTimeSec = clickedTime.getTime();
              if (clickedTimeSec < Number(expireTime)) {
                this.refreshToken()
              } else {
                this.isTimeoutToLogin = true;
                this.logout(true);
              }
              this.generalDialogService.close();
            }
          }
        });
      }, timeoutSec);
    }
  }

  refreshToken(): Promise<void> {
    return this.oauthService.refreshToken()
      .then(() => {
        this.handleExpireTime();
        return Promise.resolve();
      })
      .catch((result) => {
        const errorResponsesRequiringUserInteraction = ['interaction_required', 'login_required', 'account_selection_required', 'consent_required'];
        if (result && result.reason && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {
          console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
          return Promise.resolve();
        }
        return Promise.reject(result);
      });
  }

  /**
   * Login
   * @param targetUrl OAuth Redirect URL
   */
  login(targetUrl?: string, isDirectLogin: boolean = true) {
    let loginParams;
    if (isDirectLogin) {
      loginParams = { identity_provider: environment.identity_provider };
    }
    this.oauthService.initLoginFlow(targetUrl || this.router.url, loginParams);
  }

  /**
   * Revoke token in redis and logout from IDP
   * @param isTimeout - Set true if the flow is session timeout
   */
  logout(isTimeout: boolean = false) {
    this.dialog.closeAll();
    this.generalDialogService.close();
    this.revokeToken().subscribe(() => {
      sessionStorage.setItem("operationCodes", "");
      // Params for cognito callback
      this.oauthService.logOut({ client_id: this.oauthService.clientId, logout_uri: environment.APP_BASE_URL });
      window.dispatchEvent(new StorageEvent('storage', {key: 'access_token'}));
    });
  }

  /**
   * Refresh user profile and permission resources
   */
  refreshUserAccess() {
    return this.getUserAccess().then((userAccess) => {
      this.permissions = userAccess.accesses;
      this.permissionSubject.next(this.permissions);

      this.userAccess = userAccess;
      this.setUserRole(this.userAccess.currentBu, this.userAccess.currentLp);
      this.setSystemAdmin();
    });
  }

  /**
   * Redirect to Login Page
   */
  navigateToLoginPage(preserveStateUrl?: string) {
    setLocalStorage('preserveStateUrl', preserveStateUrl);
    this.router.navigate(['/login']);
  }

  /**
   *  Used in Playground - Testing Purpose
   */
  forceRefreshToken(): Promise<TokenResponse> {
    return this.oauthService.refreshToken();
  }

  hasPermission(permissionId: string): boolean {
    return this.permissions.includes(permissionId);
  }

  private setUserProfile(user: any) {
    this.currentUserSubject.next(user);
  }

  /**
   * Load user profile from application (DB)
   */
  private loadAppUserProfile(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.oauthService.loadUserProfile().then(
        () => {
          return this.getUserAccess().then(
            (userProfile) => {
              // Set user profile to subject
              this.setUserProfile(userProfile);
              resolve(userProfile);
            },
            (error) => {
              reject(error);
            }
          );
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  private setUserRole(businessUnit: string, loyaltyProgram: string): Promise<any> {
    return new Promise<any>((resolve) => {
      const currentRole = this.userAccess.roles.find((role: any) => {
        return role.bu === businessUnit && role.program === loyaltyProgram;
      });
      this.userRoleSubject.next(currentRole.role);
      resolve(currentRole);
    });
  }

  private setSystemAdmin() {
    const systemAdminRole = this.userAccess.roles.find((role: any) => {
      return role.role === 'System Administrator';
    });
    this.systemAdminSubject.next(!!systemAdminRole);
  }

  /**
   * Revoke token from Redis
   */
  private revokeToken(): Observable<any> {
    // Revoke token from both server side and client side
    this.clearLocalToken();
    return this.httpOauth.get(this.oauthService.revocationEndpoint).pipe(
      map((data) => {
        return data;
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**
   * Set up session id (UUID) for cross browser access control
   */
  initAuthSessionId() {
    // UUID To Identify SAME USER in Different Devices
    if (!this.oAuthStorage.hasOwnProperty('spl.sid')) {
      this.oAuthStorage.setItem('spl.sid', uuidv4());
    }
  }

  /**
   *
   */
  private clearLocalToken() {
    this.oAuthStorage.removeItem('access_token');
    this.oAuthStorage.removeItem('spl.sid');
    this.isAuthenticatedSubject.next(false);
    this.currentUserSubject.next({} as any);
  }

  /**
   * Get user's permission/resources
   */
  getUserAccess(): Promise<any> {
    const url = `${environment.FWA_URL}/user/operation`;
    return this.httpOauth.get(url).toPromise();
  }

  /**
   * Update user prefer language
   */
  updateUserLangPreference(lan): Promise<any> {
    const url = `${environment.FWA_URL}/user/langPreferenceUpdate`;
    return this.httpOauth.post(url, lan).toPromise();
  }

  /**
   * Get code description for translate
   */
   getAllCodeDescriptions(): Promise<any> {
    const url = `${environment.FWA_URL}/param/codeDescription/all`;
    return this.httpOauth.get(url).toPromise();
  }

  /**
   * Get all modules
   */
  getAllModules() {
    const url = `${environment.FWA_URL}/param/module/all`;
    return this.httpOauth.get(url).toPromise();
  }

  /**
   * Get tags for translate
   */
  getAllTagDescriptions(): Promise<any> {
    const url = `${environment.FWA_URL}/param/tagDescription/all`;
    return this.httpOauth.get(url).toPromise();
  }

  transLanKey(lan) {
    switch (lan) {
      case 'en-us':
        return 'en';
      case 'zh-cn':
        return 'sc';
      case 'zh-hk':
      case 'zh-tw':
        return 'tc';
      default:
        return 'en';
    }
  }

  formatDescs(descriptions, key: string) {
    if (descriptions.length > 0) {
      const descs = descriptions.filter((item) => item.active).sort((lhs, rhs) => {
        return lhs.displaySeq - rhs.displaySeq;
      });
      descs.map((item: any) => {
        this.setSafetyCodeValue(item);
        item[key].map((ele, i) => {
          item[this.transLanKey(ele.lang)] = ele.description;
        })
      });
      return descs;
    } else {
      return [];
    }
  }

  setSafetyCodeValue(item: { code, value, codeValue }) {
    if (this.DEFECT_SAFETY_CODE.includes(item.code)) {
      item.value = item.codeValue;
    }
  }

  getCodeDescByCode(code: string): Array<any> {
    return this.activeCodeDesc.filter((item: any) => item.code == code);
  }

  getCodeDescByValue(value: string, code: string = '') {
    let desc = this.activeCodeDesc.filter((item: any) => item.value == value);
    if (code) {
      desc = desc.filter((item: any) => item.code == code);
    }
    return desc[0];
  }

  getCodeDescOptionsByCode(code: string) {
    const curLan = this.translate.currentLang;
    const descList = this.getCodeDescByCode(code);
    const options = [];
    descList.forEach((item: any) => {
      options.push({
        key: item.value,
        label: item[curLan],
        description: item.codeDescriptions.filter(option => this.transLanKey(option.lang) === curLan)[0]?.subDescription
      });
    });
    return options;
  }

  changeFontFamily(curLan) {
    const htmlEl: HTMLElement = document.querySelector(':root');
    htmlEl.classList.remove('en-typography');
    htmlEl.classList.remove('tc-typography');
    htmlEl.classList.remove('sc-typography');
    switch (curLan) {
      case 'en':
        htmlEl.style.setProperty('--sfui-font-family', "'Open Sans', 'Roboto', sans-serif", 'important');
        htmlEl.classList.add('en-typography');
        break;
      case 'tc':
        htmlEl.style.setProperty('--sfui-font-family', "'Noto Sans HK', 'Noto Sans SC', sans-serif", 'important');
        htmlEl.classList.add('tc-typography');
        break;
      case 'sc':
        htmlEl.style.setProperty('--sfui-font-family', "'Noto Sans SC', 'Noto Sans HK', sans-serif", 'important');
        htmlEl.classList.add('sc-typography');
        break;
    }
  }

  async upsertLoginTime() {
    const url = `${environment.FWA_URL}/user/upsertUserLastLoginTime/ADMIN_PORTAL`;

    try {
      await this.httpOauth.post(url).toPromise();
    } catch (error) {
      console.error(error);
      throw new Error();
    }
  }

  async getClientId() {
    const url = `${environment.FWA_URL}/external/param/getCognitoClient`;
    try {
      const res = await this.httpOauth.get(url, {}, false, {}).toPromise();
      this.oauthService.clientId = res.clientId;
    } catch {
      this.oauthService.clientId = environment.OAUTH_CLIENT_ID;
    }
    await this.oauthService.loadDiscoveryDocument();
  }
}
