import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.scss'],
})
export class CustomButtonComponent {

  @Input() btnText: string = '';
  @Input() btnClass: string = '';
  @Input() iconClass: string = '';
  @Input() iconFont: string = '';
  @Input() iconImg: string = '';
  @Input() disable: boolean = false;
  @Input() loading: boolean = false;
  @Input() loadingClass: string = '';
  @Input() operationCode: string = '';

  @Output() btnClick = new EventEmitter();

  onClick(): void {
    this.btnClick.emit();
  }
}
