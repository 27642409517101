import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { getCookie } from '../../util';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

interface ErrorContentModel {
  img: any;
  title: string;
  desc: string;
  isShowBtn: boolean;
  btnText?: string;
}

@Component({
  selector: 'app-general-error',
  templateUrl: './general-error.component.html',
  styleUrls: ['./general-error.component.scss'],
})
export class GeneralErrorComponent implements OnInit {
  errorContent: ErrorContentModel;
  showSideBar: boolean = false;

  isShowSideBar: boolean = false;
  errorType: string = '404';
  btnText: string = "COMMON.BACK";
  isSystemError: boolean = false;

  currentErrorCode: string = '';
  curLan: string = '';

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.curLan = getCookie('cLan');

    if(this.route.snapshot.paramMap.get('errorCode')){
      this.currentErrorCode = this.route.snapshot.paramMap.get('errorCode');
    }

    const curUrl = location.prepareExternalUrl(location.path());
    if(curUrl.includes('admin')){
      this.isShowSideBar = true;
    }else{
      this.isShowSideBar = false;
    }
  }

  ngOnInit(): void {
    this.setMultiLang();
    this.setErrorDetails();
  }

  setMultiLang(){
    this.translate.onLangChange.subscribe((event: LangChangeEvent)=>{
      this.curLan = event.lang;
    })
  }

  setErrorDetails() {
    if (!this.currentErrorCode || this.currentErrorCode === '10005') {
      this.errorType = '404';
    } else if (this.currentErrorCode === '10002') {
      this.errorType = 'noAccess';
      this.btnText = 'COMMON.BACK';
    } else if (this.currentErrorCode === '10001') {
      this.errorType = 'noAccess';
      this.btnText = ''
    } else if (this.currentErrorCode === '50004') {
      this.errorType = 'maintenance';
    } else {
      this.isSystemError = true;
    }

    switch (this.errorType) {
      case "noAccess":
        this.errorContent = {
          img: "../../../../assets/icon/general-error/no-access-right.svg",
          title: "COMMON.NO_ACCESS_TITLE",
          desc: "COMMON.NO_ACCESS_CONTENT",
          isShowBtn: true,
          btnText: this.btnText
        };
        break;
      case "404":
        this.errorContent = {
          img: "../../../../assets/icon/general-error/404.svg",
          title: "COMMON.PAGE_NOT_FOUND_TITEL",
          desc: "COMMON.PAGE_NOT_FOUND_CONTENT",
          isShowBtn: false,
        };
        break;
      case "maintenance":
        this.errorContent = {
          img: "../../../../assets/icon/general-error/hammer-wench.svg",
          title: "COMMON.SORRY_MAINTENANCE",
          desc: "COMMON.BACK_SHORTLY",
          isShowBtn: false,
        };
    }
  }

  buttonClick(): void {
    if( !this.btnText || this.btnText === 'COMMON.BACK'){
      history.go(-2);
    }
  }
}
