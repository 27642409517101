
export function setCookie(name, value, exdays: number = 1) {
  const expdate = new Date();
  expdate.setTime(expdate.getTime() + (exdays * 24 * 60 * 60 * 1000));
  document.cookie = `${name}=${value};expires=${expdate.toUTCString()};path=/`;
}

export function getCookie(cname) {
  const name = cname + "=";
  const ca = document.cookie.split(';');
  for (let item of ca) {
    let c = item.trim();
    if (c.indexOf(name) == 0) { return c.substring(name.length, c.length); }
  }
  return "";
}