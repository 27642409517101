import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as weekday from 'dayjs/plugin/weekday';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';

import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';

import { QUnitType } from 'dayjs';
import { getCookie } from '../util';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekday);
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat)

export interface PeriodModel {
  startDate: string,
  endDate: string,
  title: string,
  daysDesc: {
    date: string,
    dayDesc: string
  }[]
}

@Injectable()
export class DayjsService {
  startDayStr = 'YYYY-MM-DD 00:00:00';
  endDayStr = 'YYYY-MM-DD 23:59:59';
  Dayjs = dayjs;

  constructor(private translate: TranslateService) {}

  toLocalDate(date: string) {
    return dayjs(date);
  }
  
  isExpired(date: string) {
    return this.toLocalDate(date).isBefore(dayjs());
  }

  isSameOrBefore(date: string, day: number) {
    return date ? dayjs(date).isSameOrBefore(dayjs().subtract(day, 'd'), 'd') : '-';
  }

  localeLang(lan: string) {
    dayjs.locale(lan);
  }

  /** in a same day */
  inADay(date: string): boolean {
    return this.toLocalDate(date).isBetween(
      dayjs(dayjs().format(this.startDayStr)),
      dayjs(dayjs().format(this.endDayStr)),
      null,
      '[]'
    );
  }

  /** 
   * in a same week:
   * monday as starting, sunday as ending
   */
  inAWeek(date: string): boolean {
    return this.toLocalDate(date).isBetween(
      dayjs(dayjs().startOf('week').add(1, 'day').format(this.startDayStr)),
      dayjs(dayjs().endOf('week').add(1, 'day').format(this.endDayStr)),
      null,
      '[]'
    );
  }

  formatDate(val:any, format:any) {
    return this.toLocalDate(val).format(format);
  }

  isExceedWeek(val: any) {
    const publishedTime = dayjs(val);
    let diffTimes = dayjs().diff(publishedTime, "day");
    return diffTimes > 7;
  }

  getLastYearOfDay(year = 1): PeriodModel[] {
    let periodData: PeriodModel[] = [];
    let endDate = this.toLocalDate(dayjs().add(1, "day").format("YYYY/MM/DD"));
    let startDate = this.toLocalDate(dayjs().format("YYYY/MM/DD"));
    const diffDay = endDate.diff(startDate.subtract(year, 'year'), 'day');
    for (let index = 0; index < diffDay; index++) {
      periodData.push({
        startDate: startDate.toISOString(),
        endDate: startDate.toISOString(),
        title: this.translateInfo("day", startDate, endDate),
        daysDesc: this.getDayList()
      });
      startDate = this.toLocalDate(startDate.subtract(1, "day").format("YYYY/MM/DD"));
    }
    return periodData;
  }

  getDayList(){
    let diffDateList = [];
    let labelList = ['00:00','04:00','08:00','12:00','16:00','20:00','24:00'];
    labelList.forEach(ele => {
      diffDateList.push({
        date: ele,
        dayDesc: ele
      });
    })
    return diffDateList;
  }

  getLastYearOfWeek(year = 1): PeriodModel[] {
    let periodData: PeriodModel[] = [];
    let startDate = this.toLocalDate(dayjs().day(1).format("YYYY/MM/DD"));
    let endDate = this.toLocalDate(startDate.add(6, "day").format("YYYY/MM/DD"));
    for (let index = 0; index < 52 * year; index++) {
      periodData.push({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        title: this.translateInfo("week", startDate, endDate),
        daysDesc: this.getTwoTimeWeekList(endDate)
      })
      startDate = startDate.subtract(1, 'week');
      endDate = startDate.add(6, "day");
    }
    return periodData;
  }

  getTwoTimeWeekList(endDate){
    let diffDateList = [];
    let labelList = [-6,-5,-4,-3,-2,-1,0];
    labelList.forEach(ele => {
      diffDateList.push({
        date: dayjs(endDate).add(ele, "day"),
        dayDesc: this.translateInfo("weekday", dayjs(endDate).add(ele, "day"), null)
      });
    })
    return diffDateList;
  }

  getLastYearOfMonth(year = 1): PeriodModel[] {
    let periodData: PeriodModel[] = [];
    let startDate = this.toLocalDate(dayjs().date(1).format("YYYY/MM/DD"));
    let endDate = this.toLocalDate(startDate.endOf('month').format("YYYY/MM/DD"));
    for (let index = 0; index < 12 * year; index++) {
      periodData.push({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        title: this.translateInfo("month", startDate, null),
        daysDesc: this.getMonthList(startDate)
      })
      startDate = startDate.subtract(1, 'month');
      endDate = this.toLocalDate(startDate.endOf('month').format("YYYY/MM/DD"));
    }

    return periodData;
  }

  getMonthList(startDate) {
    let diffDateList = [];
    let labelList = [1,5,10,15,20,25];
    labelList.forEach(item => {
      diffDateList.push({
        date: dayjs(startDate).date(item),
        dayDesc: this.translateInfo("monthday", dayjs(startDate).date(item), null)
      });
    })
    diffDateList.push({
      date: dayjs(startDate).endOf('month'),
      dayDesc: this.translateInfo("monthday", dayjs(startDate).endOf('month'), null)
    });
    return diffDateList;
  }

  getLastYearOfQuarter(nowDate: Date, year = 1): PeriodModel[] {
    let periodData: PeriodModel[] = [];
    let startDate = this.toLocalDate(dayjs(nowDate).startOf('quarter').format("YYYY/MM/DD"));
    let endDate = this.toLocalDate(startDate.endOf('quarter').format("YYYY/MM/DD"));
    for (let index = 0; index < 4 * year; index++) {
      periodData.push({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        title: this.translateInfo("quarter", startDate, null),
        daysDesc: this.getTwoTimeQuarterList(startDate)
      })
      startDate = startDate.subtract(1, 'quarter');
      endDate = this.toLocalDate(startDate.endOf('quarter').format("YYYY/MM/DD"));
    }
    return periodData;
  }

  getTwoTimeQuarterList(startDate) {
    let diffDateList = [];
    for (let index = 0; index < 3; index++) {
      diffDateList.push({
        date: dayjs(startDate).add(index, "month"),
        dayDesc: this.translateInfo("quarterday", dayjs(startDate).add(index, "month"), null)
      });
    }
    return diffDateList;
  }

  translateInfo(type: string, startDate: any, endDate: any, lang: string = null) {
    const localLang = lang || getCookie('cLan');
    let title = "";
    const dayTra = this.translate.instant("OVERVIEW.DAY");
    const monthTra = this.translate.instant("OVERVIEW.MONTH");
    const yearTra = this.translate.instant("OVERVIEW.YEAR");
    const to = this.translate.instant("COMMON.TO");
    let translate = `DD-MM-YYYY`;
    switch (type) {
      case "day":
        translate = `YYYY[${yearTra}]MM[${monthTra}]DD[${dayTra}]`;
        const dayDesc = localLang === "en" ? `${this.formatDate(startDate, "DD MMM YYYY")}` : `${this.formatDate(startDate, translate)}`;
        title = `${dayDesc}`;
        break;
      case "week":
        const startDateDesc = this.formatDate(startDate, translate);
        const endDateDesc = this.formatDate(endDate, translate);
        title = `${startDateDesc} ${to} ${endDateDesc}`;
        break;
      case "month":
        translate = `YYYY[${yearTra}]MM[${monthTra}]`;
        const monthDesc = localLang === "en" ? `${this.formatDate(startDate, "MMM YYYY")}` : `${this.formatDate(startDate, translate)}`;
        title = `${monthDesc}`;
        break;
      case "quarter":
        title = this.translate.instant("OVERVIEW.Q_NUMBER_YEAR", {
          qnumber: dayjs(startDate).quarter(),
          qyear: this.formatDate(startDate, "YYYY")
        });
        break;
      case "weekday":
      case "monthday":
        title =this.formatDate(startDate, "DD-MM");
        break;
      case "quarterday":
        translate = `MM[${monthTra}]`;
        title = localLang === "en" ? `${this.formatDate(startDate, "MMM")}` : `${this.formatDate(startDate, translate)}`;
        break;
      default:
        break;
    }
    return title;

  }

  getDateDiff(startDate: string, endDate: string, type: QUnitType = "day") {
    return dayjs(endDate).diff(dayjs(startDate), type);
  }

  addDate(date: string, addNumber: number, type: QUnitType = "day") {
    return this.toLocalDate((dayjs(date).add(addNumber, type).toISOString()));
  }
}
