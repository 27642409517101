<sfui-side-menu-layout>
  <sfui-side-menu
    sfuiSideMenuLayoutMenu
    headerTitle="{{ 'SHELL.FRONTLINE_WORKPLACE' | translate }}"
    headerSubtitle="{{ 'SHELL.MANAGEMENT_PORTAL' | translate }}"
    [logoUrl]="logoUrl"
    [logoClass]="languageCurId == 'en'? '': 'zh-lang-logo'"
  >
    <sfui-side-menu-section
      *ngFor="let selection of sideMenuList"
      [class]="selection.class"
      [title]="selection.title ? (selection.title | translate) : ''"
    >
      <ng-container *ngFor="let button of selection.button">
        <sfui-side-menu-button
          *ngIf="button.type === 'button'"
          [label]="button.label | translate"
          [ngRouterLink]="button.ngRouterLink"
          [icon]="button.icon"
          [iconUrl]="button.iconUrl"
          [iconTheme]="button.iconTheme"
        ></sfui-side-menu-button>

        <sfui-side-menu-collapsible
          *ngIf="button.type === 'collapsible'"
          [label]="button.label | translate"
          [icon]="button.icon"
          [iconTheme]="button.iconTheme"
        >
          <sfui-side-menu-button
            *ngFor="let item of button.button"
            [label]="item.label | translate"
            [ngRouterLink]="item.ngRouterLink"
          ></sfui-side-menu-button>
        </sfui-side-menu-collapsible>
      </ng-container>
    </sfui-side-menu-section>
    <sfui-side-menu-footer class="user-footer">
      <app-avatar width="262px" [displayName]="userInfo.displayName" [title]="userInfo.title" [email]="userInfo.email" [department]="userInfo.department"></app-avatar>
    </sfui-side-menu-footer>
    <sfui-side-menu-footer class="control-footer">
      <div class="language-selection">
        <div class="mat-menu-container" mat-button [matMenuTriggerFor]="aboveMenu">
          <span class="lan-img"></span>
          <span class="lan-txt">{{ selectedLan ? selectedLan.selectedDisplay : "" }}</span>
          <span class="triangle-img"></span>
        </div>
        <mat-menu #aboveMenu id="menuBtn">
          <button
            class="memu-button"
            mat-menu-item
            *ngFor="let option of languageOptions"
            [ngClass]="[option.key === selectedLan.key ? 'memu-select-option' : '', option.key]"
            (click)="onLanSelectionChange(option.key)"
          >{{ option.label }}</button>
        </mat-menu>

      </div>
      <button mat-stroked-button class="logout-button" (click)="showDialog()">{{ "SHELL.LOGOUT" | translate }}</button>
    </sfui-side-menu-footer>
  </sfui-side-menu>
  <div class="content-wrapper">
    <router-outlet></router-outlet>
  </div>
</sfui-side-menu-layout>

<app-general-dialog [dislogParam]="dislogParam"></app-general-dialog>

<app-message-dialog></app-message-dialog>