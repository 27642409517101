<div class="system-message-dialog">
    <div class="toward left" *ngIf="showDialog">
        <span class="material-icons" (click)="clickLeft()">chevron_left</span>
    </div>
    <sfui-dialog [isOpen]="showDialog" isBlocking="{{true}}">
        <sfui-dialog-content>
            <div class="rectangle"></div>
            <div class="slider-container">
                <div class="slider">
                    <ng-container *ngFor="let item of messageArray;let i  = index">
                        <div class="slide">
                            <div class="header">
                                <div class="top">
                                    <div class="type">{{'SYSTEM_MESSAGE.NEW_VERSION' | translate}}</div>
                                    <div class="date">01-03-2021 12:00</div>
                                </div>
                                <div class="title">
                                    New Uniform 2021
                                </div>
                                <div class="desc">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. In condimentum sa gittis
                                    massa
                                    vel.
                                    Amet
                                    felis
                                    tellus eu sed.
                                </div>
                            </div>
                            <div class="content">
                                <div class="image">
                                    <img [src]="item?.src || '../../../../../assets/icon/system-message/image-not-available.svg'" alt="">
                                    <div *ngIf="!item?.src" class="image-not-avaiable">{{ 'SYSTEM_MESSAGE.IMAGE_NOT_AVAILABLE' | translate }}</div>
                                </div>
                                <div class="store-icon-container">
                                    <div class="icons">
                                        <img src="../../../../../assets/icon/system-message/logo-huawei.svg" alt="">
                                        <img src="../../../../../assets/icon/system-message/logo-google.svg" alt="">
                                    </div>
                                    <div class="text ">{{ 'SYSTEM_MESSAGE.CLICK_ICON_TO_UPDATE' | translate }}</div>
                                    <div class="divider"></div>
                                    <div class="app_store">
                                        <img src="../../../../../assets/icon/system-message/logo-apple.svg" alt="">
                                        <div class="text">{{ 'SYSTEM_MESSAGE.CONTACT_FOR_DOWNLOAD' | translate }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </sfui-dialog-content>
        <sfui-dialog-footer class="footer">
            <sfui-checkbox class="never_show" [checked]="neverShow"  (checkedChange)="checkedChange($event)">
                {{ 'SYSTEM_MESSAGE.NEVER_SHOW_AGAIN' | translate }}
            </sfui-checkbox>
            <sfui-dialog-button type="primary" (buttonClick)="dialogConfirm()">
                {{ 'COMMON.GOT_IT' | translate }}
            </sfui-dialog-button>
        </sfui-dialog-footer>
    </sfui-dialog>
    <div class="toward right" *ngIf="showDialog">
        <span class="material-icons" (click)="clickRight()">chevron_right</span>
    </div>
    <div class="spots" *ngIf="showDialog">
        <div class="spot" [ngClass]="{'active': currentIndex === 0}" (click)="changeCurrentIndex(0)"></div>
        <div class="spot" [ngClass]="{'active': currentIndex === 1}" (click)="changeCurrentIndex(1)"></div>
        <div class="spot" [ngClass]="{'active': currentIndex === 2}" (click)="changeCurrentIndex(2)"></div>
    </div>
</div>