<div *ngIf="!isSystemError" class="general-error">
  <div class="general-error-header" *ngIf="!isShowSideBar">
    <div
      [ngClass]="{
        'en-logo': curLan == 'en',
        'tc-logo': curLan == 'tc',
        'sc-logo': curLan == 'sc'
      }"
    ></div>
    <div class="general-error-header-title">
      <span class="general-error-header-title-left">{{
        "LOGIN.FRONTLINE" | translate
      }}</span>
      <span class="general-error-header-title-right">{{
        "LOGIN.PORTAL" | translate
      }}</span>
    </div>
  </div>

  <div class="general-error-showbar-header" *ngIf="isShowSideBar">
    <span class="general-error-showbar-header-left">{{
      errorContent.title | translate
    }}</span>
  </div>

  <div
    class="general-error-content"
    [class.general-error-showbar-content]="isShowSideBar"
  >
    <div class="general-error-content-wrapper">
      <img [src]="errorContent.img" class="general-error-content-img" alt="" />
      <div class="general-error-content-title">
        {{ errorContent.title | translate }}
      </div>
      <div
        class="general-error-content-desc"
        [innerHTML]="errorContent.desc | translate"
      ></div>
      <div *ngIf="errorContent.isShowBtn" class="general-error-content-btn">
        <app-custom-button
          *ngIf="errorContent.btnText"
          [btnText]="errorContent.btnText | translate"
          btnClass="outlined-btn"
          (btnClick)="buttonClick()"
        ></app-custom-button>
      </div>
    </div>
  </div>
</div>

<!-- System Error Dialog -->
<sfui-dialog
  [isOpen]="isSystemError"
  dialogClass="demo-dialog"
  isBlocking="{{true}}"
  [width]="280"
  [height]="236"
>
  <sfui-dialog-content >
    <div class="system-error-wrapper">
      <img alt="" src="../../../../assets/icon/general-error/system-error.svg" class="system-error-wrapper-img" />
      <div class="system-error-wrapper-title">{{ 'COMMON.SERVER_ERROR_TITLE' | translate }}</div>
      <div class="system-error-wrapper-content" [innerHTML]="'COMMON.SERVER_ERROR_CONTENT' | translate:{code:currentErrorCode}"></div>
    </div>
  </sfui-dialog-content>
  <sfui-dialog-footer>
    <sfui-dialog-button
      type="default"
      (buttonClick)="buttonClick()"
    >
    {{ 'COMMON.GOT_IT' | translate }}
    </sfui-dialog-button>
  </sfui-dialog-footer>
</sfui-dialog>
