import { ErrorHandler, Injectable } from '@angular/core';
import { Logger } from './logger.service';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';

const LOCAL_ENV_NAME = 'LOCAL-DEV';
const log = new Logger('Runtime Error');
@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private userService: UserService, private router: Router) {}

  handleError(error: Error) {
    const errorMetaData = {
      userEmail: this.userService.currentUserInfo.email,
      uri: this.router.url,
    };
    log.error(`🐛`, error, errorMetaData);
  }
}
