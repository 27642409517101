<div class="user-avatar" [ngClass]="{ 'user-avatar-disable': disable }" [ngStyle]="{ width: width }">
  <div class="user-avatar-monogram">
    <span
      class="user-avatar-monogram-text"
      [ngClass]="{ 'user-avatar-monogram-disable-text': disable }"
      >{{ initials }}</span
    >
  </div>
  <div
    class="user-avatar-info"
    [ngClass]="{ 'user-avatar-info-border': isShowRightBorder }"
  >
    <div class="user-avatar-info-name-box">
      <div class="user-avatar-info-name-box-text"
        [ngClass]="{ 'user-avatar-info-name-box-disable-text': disable }"
      >
        {{ displayName }}
      </div>
      <div *ngIf="disable" class="user-avatar-info-name-box-disable">
        {{'COMMON.DISABLED' | translate}}
      </div>
    </div>
    <div
      class="user-avatar-info-title"
      [ngClass]="{ 'user-avatar-info-disable-title': disable }"
    >
      {{ title }}{{ department ? ", " : "" }}{{ department }}
    </div>
    <div class="user-avatar-info-email" *ngIf="email">{{ email }}</div>
  </div>
</div>
