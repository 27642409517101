export enum DefectStatusEnum{
  New = "N",
  InProgress = "I",
  Completed = "CP",
  Cancelled = "CA",
  Closed = "CL",
}

export enum DefectActionType {
  New = "NEW",
  Reported = "REPORTED",
  PassTo = "PASS_TO",
  Approved = "APPROVED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
  Rejected = "REJECTED",
  DefectType = "DEFECT_TYPE",
  Closed = "CLOSED",
  AssignTo = "ASSIGN_TO",
  LocationUpdate = "LOCATION_UPDATED",
  CancelledLocationUpdate  = "CANCELLED_FOR_LOCATION_UPDATED"
}

export enum ErrorCode {
  ADUserDoesNotExist = "10020"
}