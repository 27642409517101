import { Directive, Input, ElementRef, AfterViewInit } from "@angular/core";
import { UserService } from '../../core/services/user.service';

@Directive({
  selector: "[operationCode]",
  host: {
    "[attr.id]": "getCode()",
    "[attr.name]": "getCode()"
  }
})

export class OperationCodeDirective implements AfterViewInit {
  @Input() operationCode;

  constructor(
    private _elementRef: ElementRef,
    private userService: UserService,
  ) {}

  ngAfterViewInit(): void {
    this.checkDisable();
  }

  checkDisable() {
    let curCode = this.operationCode;
    let PERMISSIONS = this.userService.permissions;
    let _temp = PERMISSIONS ? PERMISSIONS : [];
    let needRemove = false;

    if (curCode instanceof Array) {
      let isInclude = false;
      for (const c of curCode) {
        if (c && _temp.includes(c)) {
          isInclude = true;
          break;
        }
      }

      needRemove = !isInclude;
    } else {
      // string
      if (curCode) {
        needRemove = _temp.indexOf(curCode) == -1;
      }
    }

    if (needRemove) {
      let el: HTMLElement = this._elementRef.nativeElement;
      this.removeParentNode(el);
    }
  }

  removeParentNode(el) {
    if (el.parentNode) {
      el.parentNode.removeChild(el);
    } else {
      setTimeout(() => {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        }
      }, 0);
    }
  }

  getCode() {
    return this.operationCode;
  }
}
