import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SortedColumn, SnackbarType } from 'swire-frontline-ui';
import { HttpOauth } from '../common/service/http-oauth';
import { TranslateService } from '@ngx-translate/core';

export type FilterKey = 'tag' | 'state' | 'status' | 'type';
export type FilterKey_ROUTE = 'buildings' | 'checkTypes' | 'statuses' | "showDeActiveFlag";

export interface CurSort {
  sortedColumns?: SortedColumn[];
  filters?: any;
  searchText?: string;
  page?:number,
  buttonActive?:boolean
}

export interface SnackbarModal {
  type: SnackbarType;
  message: string;
}

const DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'patrolStartDate',
      order: 'desc'
    }
  ],
  filters: [],
  searchText: '',
  page: 0,
  buttonActive :true
}

interface PopoverParams {
  e: any,
  val: string,
  id: string,
  xNum?: number,
  yNum?: number,
}

@Injectable()
export class PatrolRecordsService {
  serverPath = environment.FWA_URL;
  constructor(
    private httpOauth: HttpOauth,    
    private translate: TranslateService
  ) {
    DEFAULT_FILTER_SORT.filters = [
      {
        key: 'type',
        type: 'group-checklist',
        placeholder: this.translate.instant('EPATROL.ALLBUILDINGS'),
        items: [],
        selectedKeys: [],
        expandAllByDefault: false,
      },
      {
        key: 'status',
        type: 'checklist',
        placeholder: this.translate.instant('COMMON.ALL_STATUS'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      },
      {
        key: 'publish-date',
        type: 'date-range',
        placeholder: 'DD-MM-YYYY',
        value: {
          startDate: new Date(new Date().getTime()-30*24*3600*1000),
          endDate: new Date(),
        },
        dateFilter: (d: Date | null, val): boolean => {
          if (!d) {
            return false;
          }
          return +d <= new Date().getTime();
        },
        separatorText: this.translate.instant('EPATROL.TO'),
        label: this.translate.instant('EPATROL.PATROLDATE'),
      },
      {
        key: 'progress',
        type: 'checkbox',
        label: this.translate.instant('EPATROL.ABNORMALELASEDTIME'),
        checked: false,
      }
    ];
  }

// patrolRecordsService
  selectedIndex : number = 0;

  defaultFilters = DEFAULT_FILTER_SORT;
  curSort: CurSort = DEFAULT_FILTER_SORT;

  // 存储当前选择的数据 tathis.patrolRecordsService.nowSelectDa.dateValue
  nowSelectData ={
    dateValue:new Date(),
    buildCode:null,
    buttonTabVal:'IP',
  }
  initSelectData() {
    this.nowSelectData ={
      dateValue:new Date(),
      buildCode:null,
      buttonTabVal:'IP',
    }
  }

  initUserSort() {
    this.curSort = DEFAULT_FILTER_SORT;
  }

  //提示框跟着鼠标移动
  mouseoverTag({ e, val = '', id, xNum = 10, yNum = 20 }: PopoverParams) {
    let floatTipRect = document.getElementById(id);
    e.stopPropagation();
    e.preventDefault()
    floatTipRect.style.left = e.clientX - xNum + "px"
    floatTipRect.style.top = e.clientY + yNum + "px"
    floatTipRect.style.display = "block"
    floatTipRect.innerHTML = val;
  }

  mouseOutTag(e, id) {
    e.stopPropagation();
    e.preventDefault()
    document.getElementById(id).style.display = "none";
  }

  /***********************************************/
  //getPatrolRecords
  getPatrolRecords(params) {
    const url = `${this.serverPath}/epatrol/patrols/list`;
    return this.httpOauth.post(url,params);
  }

  getCodeDescription(code): Observable<any> {
    const url = `${this.serverPath}/param/codeDescription/code?codes=${code}`;
    return this.httpOauth.get(url, {}, false);
  }

  allModuleType(optionCodes): Observable<any> {
    const url = `${this.serverPath}/epatrol/site/aclWithBuilding/all?moduleType=PATROL&optionCodes=${optionCodes}`;
    return this.httpOauth.get(url, {}, false);
  }

  getPatrol(patrolId): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrols/${patrolId}`;
    return this.httpOauth.get(url, {}, false);
  }

  updateRemarks(patrolId,params): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrols/${patrolId}`;
    return this.httpOauth.put(url,params);
  }

  updateRecordRemarks(patrolRecordId,params): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrolRecord/${patrolRecordId}`;
    return this.httpOauth.put(url,params);
  }

  exportPatrolRecord(params): Observable<any> {
    const url = `${this.serverPath}/report/patrolRecord/detail`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        console.log(params);
        this.httpOauth.downloadFile(res, `${params.downloadName}.${params.exportFormat}`);
      })
    );
  }

  downloadSnapshot(patrolId,downloadName): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrolRecord/snapshots/download/batch/${patrolId}`;
    return this.httpOauth.post(url, {}, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${downloadName}.zip`);
      })
    );
  }

  patrolsEnd(params,patrolId): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrols/end/${patrolId}`;
    return this.httpOauth.put(url,params);
  }

  buildingAclAll(optionCodes): Observable<any> {
    const url = `${this.serverPath}/epatrol/building/acl/all?moduleType=PATROL&optionCodes=${optionCodes}`;
    return this.httpOauth.get(url,{},false);
  }

  patrolsSummary(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrols/summary`;
    return this.httpOauth.post(url,params,{},true);
  }

  patrolsRoutesToday(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrols/routes/today`;
    return this.httpOauth.post(url,params,{},true);
  }

  // 查询用户
  getUserByDisplayName(displayName): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrols/user/by/name?displayName=${displayName}`;
    return this.httpOauth.get(url);
  }

  // 保存check info
  saveCheckInfo(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/patrols/save/check-info?patrolId=${params.patrolId}&userId=${params.userId}`;
    return this.httpOauth.put(url, params);
  }
}
