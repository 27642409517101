import { Injectable } from '@angular/core';
import { Observable,BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SortedColumn, SnackbarType } from 'swire-frontline-ui';
import { HttpOauth } from '../common/service/http-oauth';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash-es';
import { UserService } from '../core/services/user.service';
import { SystemParameterService } from './system-parameter.service';

export type FilterKey = 'tag' | 'state' | 'status' | 'type' | 'progress';
export type FilterKey_ROUTE = 'buildings' | 'checkTypes' | 'statuses' | "showDeActiveFlag";

export interface CurSort {
  sortedColumns?: SortedColumn[];
  filters?: any;
  searchText?: string;
  page?:number
}

export interface SnackbarModal {
  type: SnackbarType;
  message: string;
}

const DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'sitecode',
      order: 'asc'
    }
  ],
  filters: [],
  searchText: '',
  page: 0 ,
}
const DEFAULT_FILTER_SORT_ROUTE: CurSort = {
  sortedColumns: [
    {
      columnKey: 'routeName',
      order: 'asc'
    }
  ],
  filters: [],
  searchText: '',
  page: 0 ,
}
const EXPORT_REPORT_SCHEDULER: CurSort = {
  sortedColumns: [
    {
      columnKey: 'generateDate',
      order: 'desc'
    }
  ],
  filters: [],
  page: 0 ,
}
//route - location 表格排序
const DEFAULT_SORT_ROUTE_LOCATION: CurSort = {
  sortedColumns: [
    {
      columnKey: 'patrolSeq',
      order: 'asc'
    }
  ],
}
//route - schedule 表格排序
const DEFAULT_SORT_ROUTE_SCHEDULE = [{
    columnKey: 'scheduleStartTime',
    order: 'asc'
  }]

//Reuse NFC 过滤条件
const REUSE_NFC: CurSort = {
  filters: [],
}

@Injectable()
export class EPatrolSettingsService {
  serverPath = environment.FWA_URL;
  constructor(
    private httpOauth: HttpOauth,    
    private userService: UserService,
    private translate: TranslateService,
    private systemParameterService: SystemParameterService,
  ) {
    DEFAULT_FILTER_SORT.filters = [
      {
        key: 'type',
        type: 'group-checklist',
        placeholder: this.translate.instant('EPATROL.ALLBUILDINGS'),
        items: [],
        selectedKeys: [],
        expandAllByDefault: true,
      },
      {
        key: 'status',
        type: 'checklist',
        placeholder: this.translate.instant('EPATROL.ALLNFCSTATUS'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      },
      {
        key: 'status1',
        type: 'checklist',
        placeholder: this.translate.instant('EPATROL.ALLQRCODESTATUS'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      }
    ];
    DEFAULT_FILTER_SORT_ROUTE.filters = [
    {
      key: 'buildings',
      type: 'group-checklist',
      placeholder: this.translate.instant('EPATROL.ALLBUILDINGS'),
      items: [],
      selectedKeys: [],
      expandAllByDefault: true,
    },
    {
      key: 'chekpoint_type',
      type: 'dropdown',
      options: [],
      selectedKey: '',
    },
    {
      key: 'status',
      type: 'checklist',
      selectAllText: this.translate.instant('EPATROL.SHOWALL'),
      placeholder: this.translate.instant('EPATROL.SHOWALL'),
      selectedKeys:['PENDING', 'APPROVED'],
      items: []
    },
    {
      key: 'progress',
      type: 'checkbox',
      label: this.translate.instant('EPATROL.SHOW_DEACTIVATED_ONLY'),
      checked: false,
    },
  ];

    EXPORT_REPORT_SCHEDULER.filters=[
      {
        key: 'report_date',
        type: 'date-range',
        placeholder: '',
        value: {
          startDate: null,
          endDate: null,
        },
        dateFilter: (d: Date | null): boolean => {
          if (!d) {
            return false;
          }
          return +d <= new Date().getTime();
        },
        label: this.translate.instant('EPATROL.EXPORT_SCHEDULE_DATE'),
        minWidth: 616
      },
      {
        key: 'progress',
        type: 'checkbox',
        label: this.translate.instant('EPATROL.SHOW_FAILED_CASE_ONLY'),
        checked: false,
      }
    ];
    REUSE_NFC.filters = [
      {
        key: 'type',
        type: 'group-checklist',
        placeholder: this.translate.instant('EPATROL.ALL_PREVIOUS_BUILDINGS'),
        items: [],
        selectedKeys: [],
        expandAllByDefault: true,
      }
    ];
  }
  
  dayLang = {
    'en': {
      1: "Mon",
      2: "Tue",
      3: "Wed",
      4: "Thu",
      5: "Fri",
      6: "Sat",
      7: "Sun",
    },
    'sc': {
      1: "周一",
      2: "周二",
      3: "周三",
      4: "周四",
      5: "周五",
      6: "周六",
      7: "周天",
    },
    'tc': {
      1: "周壹",
      2: "周二",
      3: "周三",
      4: "周四",
      5: "周五",
      6: "周六",
      7: "周天",
    },
  }

  kioskModeColumns = [
    {
      columnKey: 'displayName',
      dataField: 'displayName',
      caption: this.translate.instant('USER_MANAGEMENT.DISPLAYNAME'),
      width: 240,
      allowSorting: true,
      dataCellClassName: 'column-code-cell',
    },
    {
      columnKey: 'email',
      dataField: 'email',
      caption: this.translate.instant('USER_MANAGEMENT.EMAIL'),
      allowSorting: true,
      dataCellClassName: 'column-code-cell',
    },
    {
      columnKey: 'department',
      dataField: 'department',
      caption: this.translate.instant('USER_MANAGEMENT.DEPARTMENT'),
      width: 240,
      allowSorting: true,
      dataCellClassName: 'column-loc-code-cell',
    }
  ];

  departmentsDropdown = {
    key: 'all_departments',
    type: 'dropdown',
    options: [
      { key: 'all_departments', label: this.translate.instant('USER_MANAGEMENT.ALL_DEPARTMENTS') }
    ],
    selectedKey: 'all_departments',
  }

  snackbar: SnackbarModal = {
    type: 'success',
    message: ''
  };

  selectedIndex : number = 0;
  anormalySumarySiteCode: string = '';

  curSort: CurSort = DEFAULT_FILTER_SORT;
  curRouteSort: CurSort = DEFAULT_FILTER_SORT_ROUTE;
  curRouteLocationSort: CurSort = DEFAULT_SORT_ROUTE_LOCATION;
  curRouteSchduleSort = DEFAULT_SORT_ROUTE_SCHEDULE;
  exportReportSchduler:CurSort = EXPORT_REPORT_SCHEDULER;
  REUSE_NFC:CurSort = REUSE_NFC;

  routeCreateQrcodeLoadingShow = new BehaviorSubject<any>(false);

  setDialogParam(val) {
    this.routeCreateQrcodeLoadingShow.next(val);
  }

  //初始化checklist
  checkListInit(checkList,options) {
    let list = checkList.map((item) => {
      let optionInfo = '';
      if (item.responseType == 'Checkboxs' || item.responseType == 'Multiple Choice') {
        let checkOptions = cloneDeep(item[options])
        checkOptions = checkOptions.filter((item) => {
          return item.deleted == false
        })
        const option = checkOptions.map((optionItem) => {
          return optionItem.description
        })
        optionInfo = option.join('/')
        if(checkOptions[0] && checkOptions[0].optionType == "NA"){
          optionInfo = this.translate.instant('EPATROL.NORMALABMORMAL')
        }
        if(checkOptions[0] && checkOptions[0].optionType == "YN"){
          optionInfo = this.translate.instant('EPATROL.YESNO')
        }
      } else if (item.responseType == 'Text Input') {
        optionInfo = this.translate.instant('EPATROL.MAX') + ': ' + item.maxOfCharacters + ' ' + this.translate.instant('EPATROL.WORDS')
      } else if (item.responseType == 'Numeric Input') {
        let chart = '';
        if (item.minValue) {
          chart += this.translate.instant('EPATROL.MINEG') + ': ' + item.minValue
          if (item.maxValue) {
            chart += ','
          }
        }
        if (item.maxValue) {
          chart += this.translate.instant('EPATROL.MAXEG') + ': ' + item.maxValue
        }
        optionInfo = chart || '-';
      }
      return {
        ...item,
        taskNo: item.taskNo,
        description: item.description,
        responseType: item.responseType,
        optionInfo: optionInfo ? optionInfo : '-',
        expiryDate: item.expiryDate ? item.expiryDate : '-',
      }
    })
    return list;
  }
  
  //初始化多语言接口数据
  initCodeDescription(res){
    let langList = res.map(item =>{
      let langObj = {}
      item.codeDescriptions.forEach(langItem =>{
        if(langItem.lang ==='en-us'){
          langObj['en'] = langItem['description']
        }else if(langItem.lang ==='zh-cn'){
          langObj['sc'] = langItem['description']
        }else{
          langObj['tc'] = langItem['description']
        }
      })
      return {
        value:item.value,
        langObj
      }
    })
    return langList
  }

  setLangValue(value,langList){
    let  str = ''
    langList.forEach(item =>{
      if(item.value === value){
        str = item.langObj[this.translate.currentLang]
      }
    })
    return str
  }

  checkDataEmpty(field, errorMsg, errMessage) {
    let isEmpty = false
    if (!field) {
      isEmpty = true;
      errorMsg[errMessage] = 'COMMON.FIELD_REQUIRED';
    } else {
      errorMsg[errMessage] = '';
    }
    return isEmpty
  }
  
  getBuildings(){
    const buildList = []
    const langName = {
      en: "name",
      sc: "cnName",
      tc: "hkName"
    }
    const lang = this.translate.currentLang;
    return this.allModuleType('FWA_OP:rRoute').toPromise().then(res => {
      res.forEach((item, index) => {
        let buildings = item.buildings.map((buildingsItem) => {
          return {
            key: buildingsItem.id,
            label: buildingsItem[langName[lang]],
            value: buildingsItem.id,
          }
        })
        buildList[index] = {
          label: item[langName[lang]],
          children: buildings,
          MoCode:item.code
        }
      })
      return buildList;
    })
  }

  async getExportExcelSwitch(){
    let sign = false;
    const params = {
      sortBy: 'id',
      order: 'asc',
      page: 0,
      size: 20,
      searchText: 'ExportExcelForPatrolRecords'
    }
    const exportExcelSwitch = await this.systemParameterService.getSystemParameterList(params).toPromise();
    if(exportExcelSwitch.content.length != 0){
      sign = exportExcelSwitch.content[0].value == 'ON';
    }
    return sign
  }


  initUserSort() {
    this.curSort = DEFAULT_FILTER_SORT;
  }
  initRouteTableSort() {
    this.curRouteSort = DEFAULT_FILTER_SORT_ROUTE;
  }

  initReportScheduler(){
    this.exportReportSchduler = EXPORT_REPORT_SCHEDULER;
  }

  operationCodeMeth(opr){
    return this.userService.hasPermission(opr)
  }

  operationCodeArr(optArr:Array<any>): boolean {
    return optArr.some(opt => this.userService.hasPermission(opt));
  }

  allModuleType(optionCodes): Observable<any> {
    const url = `${this.serverPath}/epatrol/site/aclWithBuilding/all?moduleType=PATROL&optionCodes=${optionCodes}`;
    return this.httpOauth.get(url, {}, false);
  }

  getCodeDescription(code): Observable<any> {
    const url = `${this.serverPath}/param/codeDescription/code?codes=${code}`;
    return this.httpOauth.get(url, {}, false);
  }

  getLocationList(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/location/list`;
    return this.httpOauth.get(url, {}, false, params);
  }

  deleteLocation(locationId): Observable<any> {
    const url = `${this.serverPath}/epatrol/location/${locationId}`;
    return this.httpOauth.delete(url);
  }

  addLocation(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/location`;
    return this.httpOauth.post(url, params);
  }

  editLocation(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/location/${params.id}`;
    return this.httpOauth.put(url, params);
  }

  getUploadKey(key) {
    const encodeId = encodeURIComponent(key);
    const url = `${this.serverPath}/storage/file/uploadUrl?key=${encodeId}`;
    return this.httpOauth.get(url, {}, true);
  }
  //获取图片
  getEpatrolFile(key) {
    const encodeId = encodeURIComponent(key);
    const url = `${this.serverPath}/epatrol/file?key=${encodeId}`;
    return this.httpOauth.get(url, {}, false, { responseType: 'blob' });
  }
  
  async uploadFile(url: string, file: File) {
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });
  }

  //获取图片
  locationQrCode(params) {
    const url = `${this.serverPath}/epatrol/location/qrcode`;
    return this.httpOauth.post(url, params);
  }

  //获取location
  uploadDownloadQRCode(id): Observable<any> {
    const url = `${this.serverPath}/epatrol/location/downloadQRCode?qrId=${id}`;
    return this.httpOauth.put(url, {});
  }

  //获取location
  getLocationDetail(locationID) {
    const url = `${this.serverPath}/epatrol/location/${locationID}`;
    return this.httpOauth.get(url, {}, false);
  }

  //获取mo详情
  locationSiteDetail(siteId) {
    const url = `${this.serverPath}/param/location/site/detail/${siteId}`;
    return this.httpOauth.get(url, {}, false);
  }

  //获取qrcode 历史记录
  qrcodeHis(locationID) {
    const url = `${this.serverPath}/epatrol/location/qrcodeHis/${locationID}`;
    return this.httpOauth.get(url, {}, false);
  }

  //获取qrcode 历史记录
  updateQrCodeStatus(params) {
    const url = `${this.serverPath}/epatrol/location/updateQrCodeStatus`;
    return this.httpOauth.get(url,{},false, params);
  }

  /******************** route **************/
  // route 列表
  getPatrolRouteList(params) {
    const url = `${this.serverPath}/epatrol/route/listPatrolRoutesByCondition`;
    return this.httpOauth.post(url, params);
  }

  saveOrUpdateRoute(params) {
    const url = `${this.serverPath}/epatrol/route/saveOrUpdate`;
    return this.httpOauth.post(url, params);
  }
  
  getPatrolRoutesById(routeId) {
    const url = `${this.serverPath}/epatrol/route/routeDetail?id=${routeId}`;
    return this.httpOauth.get(url);
  }
  //查route下的locations
  getRouteLocationsByRoute(routeId,searchBody?) {
    const url = `${this.serverPath}/epatrol/route/locations/all/${routeId}`;
    const params = { params: searchBody };
    return this.httpOauth.get(url, {}, false, params);
  }
  //查mo下的buildings
  getBuildingsListBySiteCode(searchBody): Observable<any> {
    const url = `${this.serverPath}/epatrol/building/acl/all`;
    const params = { params: searchBody };
    return this.httpOauth.get(url, {}, false, params);
  }

  //查building下的locations
  getLocationsByBuilding(buildingId) {
    const url = `${this.serverPath}/epatrol/location/building/${buildingId}`;
    return this.httpOauth.get(url);
  }

  // 保存locaiton
  saveRouteLocation(params) {
    const url = `${this.serverPath}/epatrol/route/location/save`;
    return this.httpOauth.post(url, params);
  }

  //批量下载qrcode
  downloadBatchQrcode(searchBody) {
    const params = { 
      params: searchBody,
      responseType: 'blob' 
    };
    const url = `${this.serverPath}/epatrol/route/downloadBatchQrcode`;
    return this.httpOauth.get(url, {}, false, params);
  }

  //更新qrcode状态
  updateBatchQrCode(routeId) {
    const url = `${this.serverPath}/epatrol/route/updateBatchQrCode?routeId=${routeId} `;
    return this.httpOauth.get(url, {}, false);
  }

  updateElapsedTime(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/route/updateElapsedTime/${params.id}?plannedElapsedTime=${params.plannedElapsedTime}`;
    return this.httpOauth.put(url,{});
  }
  updateThresholdTime(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/route/updateThresholdTime/${params.routeId}?thresholdTime=${params.thresholdTime}`;
    return this.httpOauth.put(url, {});
  }

  //保存RouteCheckList
  saveRouteCheckList(params) {
    const url = `${this.serverPath}/epatrol/route/checkList/save`;
    return this.httpOauth.post(url, params);
  }
  //克隆route
  cloneRoute(params) {
    const url = `${this.serverPath}/epatrol/route/clonePatrolRoute`;
    return this.httpOauth.post(url, params);
  }

  //设置route状态
  completeRouteStatus(searchBody) {
    const params = { 
      params: searchBody,
    };
    const url = `${this.serverPath}/epatrol/route/completeStatus`;
    return this.httpOauth.get(url, {}, false, params);
  }
  //正在巡逻的数量
  inprogressCount(id) {
    const url = `${this.serverPath}/epatrol/patrols/inProgress/count/routeId/${id}`;
    return this.httpOauth.get(url);
  }

   //查route下的schedule
   getRouteScheduleByRoute(searchBody) {
    const url = `${this.serverPath}/epatrol/route/listPatrolSchedulesByRouteId`;
    const params = { params: searchBody };
    return this.httpOauth.get(url, {}, false, params);
  }

  //保存schedule
  saveOrUpdateSchedule(params) {
    const url = `${this.serverPath}/epatrol/route/saveOrUpdateSchedule`;
    return this.httpOauth.post(url, params);
  }
  // schedule 判断日期是否已存在
  checkPatrolSchedule(params) {
    return this.httpOauth.post(`${this.serverPath}/epatrol/route/checkPatrolSchedule`, params);
  }
  //删除schedule
  deleteRouteSchedule(id) {
    const url = `${this.serverPath}/epatrol/route/schedule/${id}`;
    return this.httpOauth.delete(url);
  } 

  /*********************** route ************************/

  //获取nfc 详情
  qrcodeDetail(locationID) {
    const url = `${this.serverPath}/epatrol/location/nfc/${locationID}`;
    return this.httpOauth.get(url,{},false);
  }

  //获取nfc history
  listNfcTagsHistory(locationId,params) {
    const url = `${this.serverPath}/epatrol/location/listNfcTagsHistory/${locationId}`;
    return this.httpOauth.get(url,{},false,params);
  }

  //discardNfc
  discardNfc(nfcId) {
    const url = `${this.serverPath}/epatrol/location/discardNfc/${nfcId}`;
    return this.httpOauth.delete(url,{},false);
  }

  //discardNfc
  controlNfc(id,status) {
    const url = `${this.serverPath}/epatrol/location/controlNfc/${id}?status=${status}`;
    return this.httpOauth.put(url,{});
  }

  //获取checklist
  checkList(locationId,params) {
    const url = `${this.serverPath}/epatrol/location/checkList/${locationId}`;
    return this.httpOauth.get(url,{},false,params);
  }

  //checkList/batch
  checkListBatch(params) {
    const url = `${this.serverPath}/epatrol/location/checkList/batch`;
    return this.httpOauth.post(url,params);
  }

  //checkList/batch
  getQrcodeDetail(locationID) {
    const url = `${this.serverPath}/epatrol/location/qrcode/${locationID}`;
    return this.httpOauth.get(url,{},false);
  }

  ///导出历史
  reportHistory(params) {
    const url = `${this.serverPath}/epatrol/report/history/list`;
    return this.httpOauth.post(url,params);
  }

  ///导出下载
  exportBachByHistory(data,params) {
    const url = `${this.serverPath}/epatrol/export/report/exportBachByHistory/${data.id}`;
    return this.httpOauth.get(url,{},false,params).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${data.generateDate.replace(':','-')}.zip`);
      })
    );
  }

  //Export Report Scheduler
  listSchedule() {
    const url = `${this.serverPath}/epatrol/export/report/listSchedule`;
    return this.httpOauth.get(url,{},false);
  }
  reportDetail(generateHistoryId,params) {
    const url = `${this.serverPath}/epatrol/report/detail/${generateHistoryId}`;
    return this.httpOauth.get(url,{},false,params);
  }
  //site down
  exportBachBySite(params) {
    const url = `${this.serverPath}/epatrol/export/report/exportBachBySite`;
    return this.httpOauth.get(url,{},false,params).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${params.params.siteName}.zip`);
      })
    );
  }

  //exportBachByRoute
  exportBachByRoute(params) {
    const url = `${this.serverPath}/epatrol/export/report/exportBachByRoute`;
    return this.httpOauth.get(url,{},false,params).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${params.params.siteName}.zip`);
      })
    );
  }

  //download single
  exportBachByDetail(data,params) {
    const url = `${this.serverPath}/epatrol/export/report/exportBachByDetail/${data.id}`;
    return this.httpOauth.get(url,{},false,params).pipe(
      map((res: any) => {
        if(params.params.suffix == 'pdf'){
          this.httpOauth.downloadFile(res, `${data.routeName+data.patrolTime}.pdf`);
        }else{
          this.httpOauth.downloadFile(res, `${data.routeName+data.patrolTime}.xlsx`);
        }
      })
    );
  }


  //Backup Snapshot
  getBackupSnapshotList(searchBody) {
    const params = { params: searchBody };
    const url = `${this.serverPath}/epatrol/backup/listPatrolBackupMonth`;
    return this.httpOauth.get(url,{},false,params);
  }
  //未删除的数量
  getUndelPatrolBackupCount(searchBody) {
    const params = { params: searchBody };
    const url = `${this.serverPath}/epatrol/backup/getUndelPatrolBackupCount`;
    return this.httpOauth.get(url,{},false,params);
  }
  //每月的详情
  getBackupSnapshotDays(searchBody) {
    const params = { params: searchBody };
    const url = `${this.serverPath}/epatrol/backup/listPatrolBackupDays`;
    return this.httpOauth.get(url,{},false,params);
  }
  //mo的菜单
  getSiteList(optionCodes) {
    const url = `${this.serverPath}/epatrol/site/acl/all?moduleType=PATROL&optionCodes=${optionCodes}`;
    return this.httpOauth.get(url);
  }

  //floor的菜单
  getFloorList(buildingID) {
    const url = `${this.serverPath}/param/building/floor/all/${buildingID}`;
    return this.httpOauth.get(url);
  }
  
  //下载
  downloadBackUp(id) {
    const url = `${this.serverPath}/epatrol/backup/downloadBackUpByBackupId/${id}`;
    return this.httpOauth.get(url, {}, false, { responseType: 'blob' });
  }
  //更新状态
  changeDownloadStatus(searchBody) {
    const params = { params: searchBody };
    const url = `${this.serverPath}/epatrol/backup/changeDownloadStatus`;
    return this.httpOauth.get(url, {}, false, params);
  }
  //删除
  deleteBackupSnapshot(params) {
    const url = `${this.serverPath}/epatrol/backup/deleteBackupSnapshot?backupIds=${params}`;
    return this.httpOauth.delete(url);
  }

  //floor的菜单
  reportRetry(params) {
    const url = `${this.serverPath}/epatrol/export/report/retry`;
    return this.httpOauth.get(url,{},false,params);
  }

  //floor的菜单
  nfcUseList(params) {
    const url = `${this.serverPath}/epatrol/nfcUse/list`;
    return this.httpOauth.post(url, params);
  }

  // reuseNfc
  unbindNfcSite() {
    const url = `${this.serverPath}/epatrol/location/unbindNfc/site/list`;
    return this.httpOauth.get(url,{},false);
  }

  // reuseNfc
  unbindNfcBuilding(params) {
    const url = `${this.serverPath}/epatrol/location/unbindNfc/building/list`;
    return this.httpOauth.get(url,{},false,params);
  }

  // reuseNfc
  unbindNfcFloor(params) {
    const url = `${this.serverPath}/epatrol/location/unbindNfc/floor/list`;
    return this.httpOauth.get(url,{},false,params);
  }

  // reuseNfc
  unbindNfcLocation(params) {
    const url = `${this.serverPath}/epatrol/location/unbindNfc/list`;
    return this.httpOauth.get(url,{},false,params);
  }

  // reuseNfc
  nfcUpdate(params) {
    const url = `${this.serverPath}/epatrol/nfcUse`;
    return this.httpOauth.post(url,params);
  }

  //kiosk mode 
  kioskList() {
    const url = `${this.serverPath}/epatrol/password/list/kiosk`;
    return this.httpOauth.get(url,{});
  }

  passwordDisplay(id) {
    const url = `${this.serverPath}/epatrol/password/display/${id}`;
    return this.httpOauth.get(url,{});
  }

  kioskPasswordReset(params) {
    const url = `${this.serverPath}/epatrol/password/reset`;
    return this.httpOauth.post(url,params);
  }

  passwordPageUser(params) {
    const url = `${this.serverPath}/epatrol/password/page/user`;
    return this.httpOauth.post(url,params);
  }

  siteAllModuleType() {
    const url = `${this.serverPath}/param/location/site/all/moduleType?moduleType=PATROL`;
    return this.httpOauth.get(url,{});
  }

  getDepartment(): Observable<any> {
    const url = `${this.serverPath}/param/department/all`;
    return this.httpOauth.get(url);
  }

  passwordKioskSave(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/password/kiosk/save`;
    return this.httpOauth.post(url, params);
  }

  kioskDetail(id): Observable<any> {
    const url = `${this.serverPath}/epatrol/password/kiosk/detail/${id}`;
    return this.httpOauth.get(url, {});
  }

  kioskUpdate(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/password/kiosk/update`;
    return this.httpOauth.post(url, params);
  }

  /*** erron reson ***/
  getErrorReasonList(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/error-reason/list`;
    return this.httpOauth.post(url, params);
  }

  deleteErrorReasonById(id): Observable<any> {
    const url = `${this.serverPath}/epatrol/error-reason/delete/${id}`;
    return this.httpOauth.delete(url);
  }

  sortErrorReason(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/error-reason/sort`;
    return this.httpOauth.post(url,params);
  }

  saveOneErrorReason(params): Observable<any> {
    const url = `${this.serverPath}/epatrol/error-reason/save-update`;
    return this.httpOauth.post(url,params);
  }

  /****** Anomaly Summary ******/
  getAnormalyList(params): Observable<any> {
    const url = `${this.serverPath}/anormaly/list`;
    return this.httpOauth.post(url,params);
  }

  getUserInfoByEmail(text: string): Observable<any> {
    const url = `${this.serverPath}/anormaly/anormaly/by/email?email=${text}`;
    return this.httpOauth.get(url);
  }

  saveReceriverList(params): Observable<any> {
    const url = `${this.serverPath}/anormaly/save`;
    return this.httpOauth.post(url,params);
  }
}
