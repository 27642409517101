import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { SortedColumn, Pagination, SnackbarType, TableDropdownFilter } from 'swire-frontline-ui';
import { HttpOauth } from '../common/service/http-oauth';

export type FilterKey = 'active';

export interface CurSort {
  sortedColumns?: SortedColumn[];
  pagination?: Pagination;
  filters?: TableDropdownFilter<FilterKey>[];
  searchText?: string;
}

export interface SnackbarModal {
  type: SnackbarType;
  message: string;
}

const DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'roleCode',
      order: 'asc',
    },
  ],
  pagination: {
    pageSize: 20,
    totalRowCount: 0,
    pageIndex: 0,
  },
  filters: [
    {
      key: 'active',
      type: 'dropdown',
      options: [],
      selectedKey: 'active',
    },
  ],
  searchText: ''
};

@Injectable()
export class RoleManagementService {
  serverPath = environment.FWA_URL;
  constructor(
    private httpOauth: HttpOauth
  ) { }

  curSort: CurSort = DEFAULT_FILTER_SORT;
  snackbar: SnackbarModal = {
    type: 'success',
    message: ''
  };

  initRoleSort() {
    this.curSort = DEFAULT_FILTER_SORT;
  }

  getRoleManagementList(): Observable<any> {
    const url = `${this.serverPath}/rbac/role/paging/all`;
    return this.httpOauth.get(url);
  }

  getAccessControlList(): Observable<any> {
    const url = `${this.serverPath}/rbac/accessControl/accessControlGroup/all`;
    return this.httpOauth.get(url);
  }

  getRoleItemDetails(id: any): Observable<any> {
    const url = `${this.serverPath}/rbac/role/${id}`;
    return this.httpOauth.get(url);
  }

  getOperationList(): Observable<any> {
    const url = `${this.serverPath}/rbac/operation/paging/all`;
    return this.httpOauth.get(url);
  }

  addRole(roleDetails): Observable<any> {
    const url = `${this.serverPath}/rbac/role`;
    return this.httpOauth.post(url, roleDetails);
  }

  editRole(roleId, roleDetails): Observable<any> {
    const url = `${this.serverPath}/rbac/role/${roleId}`;
    return this.httpOauth.put(url, roleDetails);
  }

  checkDuplicateCode(roleCode): Observable<any> {
    const url = `${this.serverPath}/rbac/role/duplicateCheckByCode?code=${roleCode}`;
    return this.httpOauth.get(url);
  }

  deactivateRole(roleId): Observable<any> {
    const url = `${this.serverPath}/rbac/role/${roleId}/deactivate`;
    return this.httpOauth.post(url);
  }

  activateRole(roleId): Observable<any> {
    const url = `${this.serverPath}/rbac/role/${roleId}/activate`;
    return this.httpOauth.post(url);
  }

  deleteRole(roleId): Observable<any> {
    const url = `${this.serverPath}/rbac/role/${roleId}`;
    return this.httpOauth.delete(url);
  }

  getUsersByRole(roleId): Observable<any> {
    const url = `${this.serverPath}/rbac/role/${roleId}/users`;
    return this.httpOauth.get(url);
  }
}
