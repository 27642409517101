import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SortedColumn, SnackbarType, TableFilter } from 'swire-frontline-ui';
import { HttpOauth } from '../common/service/http-oauth';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, sortBy } from 'lodash-es';

export type FilterKey = 'active' | 'all_modules';

export interface CurSort {
  sortedColumns?: SortedColumn[];
  filters?: any;
  searchText?: string;
}

export interface SnackbarModal {
  type: SnackbarType;
  message: string;
}

const DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'displaySeq',
      order: 'asc'
    }
  ],
  filters: [
    {
      key: 'all_modules',
      type: 'checklist',
      items: [],
      selectedKeys: [],
    },
    {
      key: 'active',
      type: 'dropdown',
      selectedKey: 'active',
    }
  ],
  searchText: ''
}

const DEFAULT_FILTER_SORT_BUILDING: CurSort = {
  sortedColumns: [
    {
      columnKey: 'displaySeq',
      order: 'asc'
    }
  ],
  filters: [
    {
      key: 'all_modules',
      type: 'checklist',
      items: [],
      selectedKeys: [],
    },
    {
      key: 'active',
      type: 'dropdown',
      selectedKey: 'active',
    }
  ],
  searchText: ''
}

const DEFAULT_FILTER_FLOOR: CurSort = {
  sortedColumns: [
    {
      columnKey: 'seq',
      order: 'asc'
    }
  ],
  filters: [
    {
      key: 'active',
      type: 'dropdown',
      selectedKey: 'active',
    }
  ],
  searchText: ''
}

@Injectable()
export class MoBuildingsService {
  serverPath = environment.FWA_URL;
  constructor(
    private httpOauth: HttpOauth,
    private translate: TranslateService
  ) { }

  moAndBuildingFilters: TableFilter<FilterKey>[] = [
    {
      key: 'all_modules',
      type: 'checklist',
      selectAllText: this.translate.instant('COMMON.SELECT_ALL'),
      placeholder: this.translate.instant('MO_BUILDINGS.ALL_MODULES'),
      items: [],
      selectedKeys: [],
    },
    {
      key: 'active',
      type: 'dropdown',
      options: [
        {
          key: 'all',
          label: this.translate.instant('MO_BUILDINGS.ALL_STATUS'),
        },
        {
          key: 'active',
          label: this.translate.instant('COMMON.ACTIVE'),
        },
        {
          key: 'inactive',
          label: this.translate.instant('COMMON.INACTIVE'),
        },
      ],
      selectedKey: 'active',
    },
  ];

  createBuildingColumns(moduleTypeTemplate,buildingEmailsTemplate,statusTemplate){
    return [
      {
        columnKey: 'displaySeq',
        dataField: 'displaySeq',
        caption: this.translate.instant('MO_BUILDINGS.DISPLAY_SEQ'),
        width: 80,
        fixed: true,
        fixedPosition: 'left',
        allowSorting: true,
        dataCellClassName: 'column-seq-cell',
      },
      {
        columnKey: 'code',
        dataField: 'code',
        caption: this.translate.instant('MO_BUILDINGS.BUILDING_CODE'),
        width: 88,
        allowSorting: true,
        dataCellClassName: 'column-code-cell',
      },
      {
        columnKey: 'name',
        dataField: 'name',
        caption: this.translate.instant('MO_BUILDINGS.BUILDING_NAME'),
        width: 212,
        allowSorting: true,
        dataCellClassName: 'column-name-cell',
      },
      {
        columnKey: 'moduleType',
        cellTemplate: moduleTypeTemplate,
        caption: this.translate.instant('MO_BUILDINGS.MODULE_TYPES'),
        minWidth: 216,
        dataCellClassName: 'column-moduleType-cell',
      },
      {
        columnKey: 'buildingEmails',
        cellTemplate: buildingEmailsTemplate,
        caption: this.translate.instant('MO_BUILDINGS.EMAIL_ADDRESS'),
        minWidth: 172,
        dataCellClassName: 'column-buildingEmails-cell',
      },
      {
        columnKey: 'controlRoomContactrno',
        dataField: 'controlRoomContactrno',
        allowSorting: true,
        caption: this.translate.instant('MO_BUILDINGS.CONTACT_NO'),
        width: 136,
        dataCellClassName: 'column-contractNo-cell',
      },
      {
        columnKey: 'active',
        cellTemplate: statusTemplate,
        caption: this.translate.instant('COMMON.STATUS'),
        width: 108,
        fixed: true,
        fixedPosition: 'right',
        dataCellClassName: 'column-status-cell',
      },
    ];
  }

  snackbar: SnackbarModal = {
    type: 'success',
    message: ''
  };

  selectedIndex: number = 0;
  floorTabIndex: number = 0;

  curSort: CurSort = DEFAULT_FILTER_SORT;
  curBuildingSort: CurSort = DEFAULT_FILTER_SORT_BUILDING;
  curFloorSort: CurSort = DEFAULT_FILTER_FLOOR;

  initMoSort() {
    this.curSort = DEFAULT_FILTER_SORT;
  }
  initBuildingSort() {
    this.curBuildingSort = DEFAULT_FILTER_SORT_BUILDING;
  }

  initFloorSort() {
    this.floorTabIndex = 0;
    this.curFloorSort = DEFAULT_FILTER_FLOOR;
  }

  //初始化多语言接口数据
  async initCodeDescription(code) {
    let langList = [];
    await this.getCodeDescription(code).toPromise().then(res =>{
      langList = res.map(item => {
        let langObj = {}
        item.codeDescriptions.forEach(langItem => {
          if (langItem.lang === 'en-us') {
            langObj['en'] = langItem['description']
          } else if (langItem.lang === 'zh-cn') {
            langObj['sc'] = langItem['description']
          } else {
            langObj['tc'] = langItem['description']
          }
        })
        return {
          value: item.value,
          langObj
        }
      })
    })
    return langList
  }

  setCodeDescripitonValue(key,langList){
    let str = ''
    langList.forEach(item => {
      if (item.value == key) {
        str = item.langObj[this.translate.currentLang]
      }
    })
    return str
  }

  getModuleType() {
    const modeleTypeLangList = [];
    return this.getCodeDescription('FRONTLINE_SYSTEM_MODULE_TYPE').toPromise().then(res => {
      const lang = this.translate.currentLang;
      const obj = {
        en: 'en-us',
        sc: 'zh-cn',
        tc: 'zh-hk',
      }
      res.forEach(item => {
        item.codeDescriptions.forEach(des => {
          if (des.lang === obj[lang]) {
            modeleTypeLangList.push({
              key: item.value,
              label: des.description
            })
          }
        })
      })
      return sortBy(modeleTypeLangList,['key'])
    })
  }

  checkPmsCodeEmpty(buildingExtension: any, errorMsg: object, moduleType: any): boolean{
    let isEmpty = false;
    const moduleTypeArr = moduleType.split(',')
    if(buildingExtension && moduleTypeArr.includes('PATROL')){
      if(!buildingExtension.pmsBuildingCode){
        isEmpty = true;
        errorMsg['pmsCodeErrMessage'] = 'COMMON.FIELD_REQUIRED';
      }else{
        const reg =  /^[A-Za-z0-9-]+$/g;
        if(reg.test(buildingExtension.pmsBuildingCode)) {
          isEmpty = false;
          errorMsg['pmsCodeErrMessage'] = '';
        }else{
          isEmpty = true;
          errorMsg['pmsCodeErrMessage'] = 'MO_BUILDINGS.ENTER_ENGLISH_NUMBER_LINE';
        }
      }
    }else{
      errorMsg['pmsCodeErrMessage'] = '';
    }
    return isEmpty;
  }

  checkEmailWrong(buildingEmails,errorMsg){
    const regex = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    if(buildingEmails.length === 0){
      errorMsg.emailAddressErrMessage = false;
      return false;
    }
    let resultList = []
    buildingEmails.forEach(item =>{
      resultList.push(regex.test(item.emailAddress))
    })
    let isWrong = resultList.includes(false)
    if(!isWrong){
      errorMsg.emailAddressErrMessage = false;
    }else{
      errorMsg.emailAddressErrMessage = true;
    }
    return isWrong
  }

  checkDataEmpty(field,errorMsg,errMessage){
    let isEmpty = false
    if(!field){
      isEmpty = true;
      errorMsg[errMessage] = 'COMMON.FIELD_REQUIRED';
    }else{
      if(errMessage =='codeErrMessage') {
       const reg =  /^[A-Za-z0-9]+$/g;
       const regexTest = reg.test(field)
       if(regexTest) {
          errorMsg[errMessage] = '';
          isEmpty = false;
       }else{
          errorMsg[errMessage] = 'MO_BUILDINGS.CODE_MESSAGE';
          isEmpty = true;
       }
      }else{
        errorMsg[errMessage] = '';
        isEmpty = false;
      }
      
    }
    return isEmpty
  }

  // 拖拽排序后的数组
  reorderedList(event,list,seq?){
    const newArr = cloneDeep(list)
    const e = cloneDeep(event)
    newArr.splice(e.fromRowIndex, 1);
    newArr.splice(e.toRowIndex, 0, e.data)
    newArr.forEach((item:object, index:number) => item[seq] = index + 1);
    return newArr;
  }

  getCodeDescription(code): Observable<any> {
    const url = `${this.serverPath}/param/codeDescription/code?codes=${code}`;
    return this.httpOauth.get(url,);
  }

  getMoList(searchBody): Observable<any> {
    const url = `${this.serverPath}/param/location/site/all`;
    const params = { params: searchBody };
    return this.httpOauth.get(url, {}, false, params);
  }

  updateMoStatus(mo): Observable<any> {
    const url = `${this.serverPath}/param/location/site/${mo.id}`;
    return this.httpOauth.delete(url);
  }

  updateBuildingStatus(building): Observable<any> {
    const url = `${this.serverPath}/param/building/${building.id}`;
    return this.httpOauth.delete(url);
  }

  updateMo(mo): Observable<any> {
    const url = `${this.serverPath}/param/location/site/${mo.id}`;
    return this.httpOauth.put(url, mo);
  }

  getMoDetail(moId): Observable<any> {
    const url = `${this.serverPath}/param/location/site/${moId}`;
    return this.httpOauth.get(url);
  }

  saveMo(mo): Observable<any> {
    const url = `${this.serverPath}/param/location/site`;
    return this.httpOauth.post(url, mo);
  }

  checkCode(params): Observable<any> {
    const siteCodeCheckRequest = params;
    const url = `${this.serverPath}/param/location/site/checkCode`;
    return this.httpOauth.post(url, siteCodeCheckRequest);
  }

  getUploadKey(fileName: string) {
    const encodeId = encodeURIComponent(fileName);
    const url = `${this.serverPath}/storage/file/uploadUrl?key=${encodeId}`;
    return this.httpOauth.get(url, {}, true);
  }

  async uploadFile(url: string, file: File) {
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      body: file,
    });
  }

  getFileByKey(key: string) {
    const encodeId = encodeURIComponent(key);
    const url = this.serverPath + `/epatrol/file?key=${encodeId}`;
    return this.httpOauth.get(url, {}, false, { responseType: 'blob' });
  }

  getBuildingsListBySiteCode(searchBody): Observable<any> {
    const url = `${this.serverPath}/param/building/siteCode`;
    const params = { params: searchBody };
    return this.httpOauth.get(url, {}, false, params);
  }

  getOneBuildingById(id): Observable<any> {
    const url = `${this.serverPath}/param/building/${id}`;
    return this.httpOauth.get(url);
  }

  checkBuildingCode(params) {
    const url = `${this.serverPath}/param/building/checkCode`;
    return this.httpOauth.post(url, params);
  }

  saveBuilding(building): Observable<any> {
    const url = `${this.serverPath}/param/building`;
    return this.httpOauth.post(url, building);
  }
  updateBuilding(building): Observable<any> {
    const url = `${this.serverPath}/param/building/${building.id}`;
    return this.httpOauth.put(url, building);
  }

  reorderBuilding(buildingList): Observable<any> {
    const url = `${this.serverPath}/param/building/reorder`;
    return this.httpOauth.put(url, buildingList);
  }

  //floor
  getFloorList(searchBody): Observable<any> {
    const params = { params: searchBody };
    const url = `${this.serverPath}/param/building/floor/all/${searchBody.buildingID}`;
    return this.httpOauth.get(url, {}, false, params);
  }
  saveFloor(floor): Observable<any> {
    const url = `${this.serverPath}/param/building/floor`;
    return this.httpOauth.post(url, floor);
  }
  updateFloorStatus(id): Observable<any> {
    const url = `${this.serverPath}/param/building/floor/${id}`;
    return this.httpOauth.delete(url);
  }
  updateFloor(floor): Observable<any> {
    const url = `${this.serverPath}/param/building/floor/${floor.buildingFloorId}`;
    return this.httpOauth.put(url, floor);
  }
  getFloorDetail(id): Observable<any> {
    const url = `${this.serverPath}/param/building/floor/${id}`;
    return this.httpOauth.get(url);
  }
  reorderFloor(floorList): Observable<any> {
    const url = `${this.serverPath}/param/building/floor/reorder`;
    return this.httpOauth.put(url, floorList);
  }

  // 数据提交组装
  getMoBuildData(moDetail) {
    if(moDetail.verificationSwitch == 1) {
      if(moDetail.numberType == "PERCENT") {
        return {
          ...moDetail,
          maxValue: "",
          minValue: ''
        }
      }else{
        return {
          ...moDetail,
          percentValue: null
        }
      }
    }else{
      return {
        ...moDetail,
        percentValue: null,
        numberType: null,
        maxValue: "",
        minValue: ''
      }
    }
  }
}
