import { Pipe, PipeTransform } from '@angular/core';
import { DayjsService } from '../service/dayjs.service';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  constructor(
    private dayjsService: DayjsService,
  ) {

  }

  transform(value: any, format = 'YYYY-MM-DD HH:mm'): unknown {
    return this.dayjsService.formatDate(value, format);
  }

}
