import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

@Pipe({
  name: 'operation'
})
export class OperationPipe implements PipeTransform {

  constructor(
    private userService: UserService,
  ) { }
  
  transform(value: any): any {
    let curCode = value;
    let PERMISSIONS = this.userService.permissions;
    let _temp = PERMISSIONS ? PERMISSIONS : [];
    let hasOptCode = false;

    if (curCode instanceof Array) {
      let isInclude = false;
      for (const c of curCode) {
        if (c && _temp.includes(c)) {
          isInclude = true;
          break;
        }
      }
      hasOptCode = isInclude;
    } else {
      // string
      if (curCode) {
        hasOptCode = _temp.indexOf(curCode) > -1;
      }
    }
    return hasOptCode;
  }

}
