import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SortedColumn, SnackbarType, SnackbarService } from 'swire-frontline-ui';
import { HttpOauth } from '../common/service/http-oauth';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../core/services/user.service';

export type FilterKey = 'tag' | 'state' | 'status' | 'type' | 'progress';
export type FilterKey_ROUTE = 'buildings' | 'checkTypes' | 'statuses' | "showDeActiveFlag";

export interface CurSort {
  sortedColumns?: SortedColumn[];
  filters?: any;
  searchText?: string;
  page?:number
}

export interface SnackbarModal {
  type: SnackbarType;
  message: string;
}

const INIT_SNACKBAR: SnackbarModal = {
  type: 'success',
  message: ''
}

const DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'createdDate',
      order: 'desc'
    }
  ],
  filters: [],
  searchText: '',
  page: 0 ,
}

const ASSIGNED_DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'userName',
      order: 'asc'
    }
  ],
  filters: [],
  searchText: '',
  page: 0 ,
}

const ARCHIVED_DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'userName',
      order: 'asc'
    }
  ],
  filters: [],
  searchText: '',
  page: 0 ,
}

@Injectable()
export class CommonDeviceManagementService {
  serverPath = environment.FWA_URL;
  constructor(
    private httpOauth: HttpOauth,    
    private userService: UserService,
    private translate: TranslateService,
    private snackbarManager: SnackbarService,
  ) {
    DEFAULT_FILTER_SORT.filters = [
      {
        key: 'categories',
        type: 'checklist',
        placeholder: this.translate.instant('COMMON_DEVICE.ALL_CATEGORIES'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      },
      {
        key: 'status',
        type: 'checklist',
        placeholder: this.translate.instant('COMMON.ALL_STATUS'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      }
    ];
    ASSIGNED_DEFAULT_FILTER_SORT.filters = [
      {
        key: 'categories',
        type: 'checklist',
        placeholder: this.translate.instant('COMMON_DEVICE.ALL_CATEGORIES'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      },
      {
        key: 'modules',
        type: 'checklist',
        placeholder: this.translate.instant('DEVICE_MANAGEMENT.ALL_MODULES'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      },
      {
        key: 'status',
        type: 'checklist',
        placeholder: this.translate.instant('COMMON.ALL_STATUS'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      }
    ];
    ARCHIVED_DEFAULT_FILTER_SORT.filters = [
      {
        key: 'categories',
        type: 'checklist',
        placeholder: this.translate.instant('COMMON_DEVICE.ALL_CATEGORIES'),
        items: [],
        selectAllText: this.translate.instant('EPATROL.SHOWALL'),
        selectedKeys: [],
      }
    ];
  }

  selectedIndex : number = 0;
  curSort: CurSort = DEFAULT_FILTER_SORT;
  assignedCurSort:CurSort = ASSIGNED_DEFAULT_FILTER_SORT;
  archivedCurSort:CurSort = ARCHIVED_DEFAULT_FILTER_SORT;

  snackbar: SnackbarModal = {
    type: 'success',
    message: ''
  };

  tabIndex = 0;

  tabCount = {
    unassignedCount: 0,
    assignedCount: 0,
    archivedCount: 0,
    deviceCount: 0
  }

  syncAllLoading: boolean = false;
  receiveSyncStatus: boolean = false;
  syncAllLoadingChange$ = new BehaviorSubject<any>(true);

  initActionList(type?){
    const actionList = {
      New:[{
        operationCode: 'FWA_OP:archiveCommonDevice',
        type: 'archive',
        text: this.translate.instant("COMMON_DEVICE.ARCHIVE")
      }],
      Unassign: [{
        operationCode: 'FWA_OP:archiveCommonDevice',
        type: 'archive',
        text: this.translate.instant("COMMON_DEVICE.ARCHIVE")
      }],
      Active: [{
        operationCode: 'FWA_OP:uCommonDevice',
        type: 'edit',
        text: this.translate.instant("COMMON.EDIT")
      }, {
        operationCode: 'FWA_OP:lockCommonDevice',
        type: 'lock',
        text: this.translate.instant("COMMON_DEVICE.LOCK")
      }, {
        operationCode: 'FWA_OP:resetCommonDevice',
        type: 'reset',
        text: this.translate.instant("COMMON_DEVICE.RESET")
      }, {
        operationCode: 'FWA_OP:archiveCommonDevice',
        type: 'archive',
        text: this.translate.instant("COMMON_DEVICE.ARCHIVE")
      }],
      Locked: [{
        operationCode: 'FWA_OP:activateCommonDevice',
        type: 'activate',
        text: this.translate.instant("COMMON.ACTIVATE")
      }, {
        operationCode: 'FWA_OP:resetCommonDevice',
        type: 'reset',
        text: this.translate.instant("COMMON_DEVICE.RESET")
      }, {
        operationCode: 'FWA_OP:archiveCommonDevice',
        type: 'archive',
        text: this.translate.instant("COMMON_DEVICE.ARCHIVE")
      }],
      Resetting: [{
        operationCode: 'FWA_OP:resetCommonDevice',
        type: 'complete',
        text: this.translate.instant("COMMON_DEVICE.RESET_COMPLETE")
      }, {
        operationCode: 'FWA_OP:resetCommonDevice',
        type: 'failed',
        text: this.translate.instant("COMMON_DEVICE.RESET_FAILED")
      }, {
        operationCode: 'FWA_OP:archiveCommonDevice',
        type: 'archive',
        text: this.translate.instant("COMMON_DEVICE.ARCHIVE")
      }],
    }
    return type ? actionList[type] : actionList
  }

  getNameFirst(str){
    if(!str){
      return '';
    }
    let arr = str.split(" ");
    let fristCode = '';
    arr.forEach((item)=>{
      fristCode += item.substr(0,1);
    });
    return fristCode.toUpperCase();
  }

  listenSyncStatus(){
    this.receiveSyncStatus = true;
    const timer = setInterval(() => {
      if(this.receiveSyncStatus){
        this.getSyncStatus();
        return;
      }
      clearInterval(timer)
    }, 20000);
  }

  getSyncStatus(){
    this.commonDeviceSyncSyncStatus().then(res => {
      if(res.status === 'SUCCESS'){
        this.syncAllLoading = false;
        this.receiveSyncStatus = false;
        this.syncAllLoadingChange$.next(false);
        this.updateDeviceCount(true);  //同步完成后：更新总数
        this.snackbarManager.displaySnackbar({
          type: 'success',
          message: this.translate.instant('COMMON_DEVICE.ALL_DEVICES_SYNC_SUCCESS')
        })
      }
    }, () => {
      this.syncAllLoading = false;
      this.receiveSyncStatus = false;
      this.syncAllLoadingChange$.next(false);
      this.snackbarManager.displaySnackbar({
        type: 'failed',
        message: this.translate.instant('COMMON_DEVICE.SYNC_FAILED')
      })
    })
  }

  // 2023-09-07T14:37:12.000+08:00 -> 07-09-2023
  fotmatDate(str){
    if(!str){
      return ''
    }
    const arr = str.slice(0,10).split('-')
    return arr.reverse().join('-')
  }

  displaySnackbar(serverSnackbar){
    this.snackbarManager.displaySnackbar(serverSnackbar);
    this.snackbar = INIT_SNACKBAR;
  }

  initTableSort() {
    this.curSort = DEFAULT_FILTER_SORT;
  }

  initAssignedCurSortSort() {
    this.assignedCurSort = ASSIGNED_DEFAULT_FILTER_SORT;
  }

  initArchivedCurSortSort() {
    this.archivedCurSort = ARCHIVED_DEFAULT_FILTER_SORT;
  }

  hasPermission(opr){
    return this.userService.hasPermission(opr)
  }

  hasOnePermissionOfArr(oprArr){
    return oprArr.some(code => this.userService.hasPermission(code));
  }

  // device各项总数
  updateDeviceCount(total?){
    this.unassignedCount({}).subscribe((res)=>{
      this.tabCount.unassignedCount = res.data;
    })
    this.assignedCount({}).subscribe((res)=>{
      this.tabCount.assignedCount = res.data;
    })
    this.archivedCount({}).subscribe((res)=>{
      this.tabCount.archivedCount = res.data;
    })
    if(total){
      this.deviceTotalCount();
    }
  }

  // device总数
  deviceTotalCount(){
    this.deviceCount({}).subscribe((res)=>{
      this.tabCount.deviceCount = res.data;
    })
  }

  // owner接口
  getOwner(displayName): Observable<any> {
    const url = `${this.serverPath}/common/device/list/user/?displayName=${displayName}`;
    return this.httpOauth.get(url);
  }

  unassignedlist(params): Observable<any> {
    const url = `${this.serverPath}/common/device/unassigned/list`;
    return this.httpOauth.post(url, params);
  }

  categoryList(): Observable<any> {
    const url = `${this.serverPath}/common/device/category/list`;
    return this.httpOauth.post(url);
  }

  getCommonDeviceDetailById(deviceId): Observable<any> {
    const url = `${this.serverPath}/common/device/${deviceId}`;
    return this.httpOauth.get(url);
  }

  assignedList(params): Observable<any> {
    const url = `${this.serverPath}/common/device/assigned/list`;
    return this.httpOauth.post(url, params);
  }

  categorySave(params): Observable<any> {
    const url = `${this.serverPath}/common/device/category/save-update`;
    return this.httpOauth.post(url, params);
  }

  checkDuplicateMobile(params): Observable<any> {
    const regionCode = encodeURIComponent(params.regionCode)
    const url = `${this.serverPath}/common/device/check/phone?phoneNumber=${params.phoneNumber}&regionCode=${regionCode}`;
    return this.httpOauth.get(url, params);
  }

  checkDuplicateSimCardId(params): Observable<any> {
    const simCardId = encodeURIComponent(params.simCardId)
    const url = `${this.serverPath}/common/device/check/simCardId?simCardId=${simCardId}`;
    return this.httpOauth.get(url, params);
  }

  updateCommonDevice(params): Observable<any> {
    const url = `${this.serverPath}/common/device/update/device`;
    return this.httpOauth.put(url, params);
  }

  getDeviceLog(params): Observable<any> {
    const url = `${this.serverPath}/common/device/list/device/log?deviceID=${params.deviceID}&startDate=${params.startDate}&endDate=${params.endDate}&logType=${params.logType}`;
    return this.httpOauth.get(url, params);
  }

  listDeviceCategory(): Observable<any> {
    const url = `${this.serverPath}/common/device/category/list`;
    return this.httpOauth.post(url);
  }

  // 下载
  exportCommondevice(data): Observable<any> {
    const url = `${this.serverPath}/common/device/export`;
    return this.httpOauth.post(url, data,{}, false, { responseType: 'blob' });
  }

  // 同步所有设备
  commonAllDeviceSync(): Observable<any> {
    const url = `${this.serverPath}/common/device/sync/common-device`;
    return this.httpOauth.get(url);
  }
  // 同步指定设备
  commonDeviceSyncByIMEI(params): Observable<any> {
    const url = `${this.serverPath}/common/device/sync/common-device/imei?imei=${params.imei}`;
    return this.httpOauth.get(url,{}, true);
  }
  // 查询同步状态
  commonDeviceSyncSyncStatus(): Promise<any> {
    const url = `${this.serverPath}/common/device/sync/status`;
    return this.httpOauth.get(url).toPromise();
  }

  // 归档设备
  archivedList(params): Observable<any> {
    const url = `${this.serverPath}/common/device/archived/list`;
    return this.httpOauth.post(url, params);
  }

  // 归档统计
  archivedCount(params): Observable<any> {
    const url = `${this.serverPath}/common/device/archived/tab/count`;
    return this.httpOauth.post(url, params);
  }

  // assigned
  assignedCount(params): Observable<any> {
    const url = `${this.serverPath}/common/device/assigned/tab/count`;
    return this.httpOauth.post(url, params);
  }

  // unassigned
  unassignedCount(params): Observable<any> {
    const url = `${this.serverPath}/common/device/unassigned/tab/count`;
    return this.httpOauth.post(url, params);
  }

  // deviceCount
  deviceCount(params): Observable<any> {
    const url = `${this.serverPath}/common/device/tab/count`;
    return this.httpOauth.post(url, params);
  }
  // active
  activeDevice(params): Observable<any> {
    const url = `${this.serverPath}/common/device/change/status/active`;
    return this.httpOauth.put(url, params);
  }
  // archive
  archiveDevice(params): Observable<any> {
    const url = `${this.serverPath}/common/device/change/status/archive`;
    return this.httpOauth.put(url, params);
  }
  // reset
  resetDevice(params): Observable<any> {
    const url = `${this.serverPath}/common/device/change/status/reset`;
    return this.httpOauth.put(url, params);
  }
  // lock
  lockDevice(params): Observable<any> {
    const url = `${this.serverPath}/common/device/change/status/lock`;
    return this.httpOauth.put(url, params);
  }
  
  // deleteCategory
  deleteCategory(id): Observable<any> {
    const url = `${this.serverPath}/common/device/category/delete/${id}`;
    return this.httpOauth.delete(url);
  }

  // deleteCategory
  updateCategory(params): Observable<any> {
    const url = `${this.serverPath}/common/device/category/save-update`;
    return this.httpOauth.post(url,params);
  }

  // deleteCategory
  checkName(params): Observable<any> {
    const url = `${this.serverPath}/common/device/category/check/name`;
    return this.httpOauth.post(url,params);
  }

  // deviceCategory
  deviceCategory(id): Observable<any> {
    const url = `${this.serverPath}/common/device/category/${id}`;
    return this.httpOauth.get(url);
  }

  // group
  getGroupList(): Observable<any> {
    const url = `${this.serverPath}/common/device/list/group/code`;
    return this.httpOauth.get(url);
  }

  // group acl
  getGroupListByAcl(): Observable<any> {
    const url = `${this.serverPath}/common/device/list/group/code/acl`;
    return this.httpOauth.get(url);
  }

  // add common device
  addCommonDevice(params): Observable<any> {
    const url = `${this.serverPath}/common/device/save/device`;
    return this.httpOauth.post(url, params);
  }
}
