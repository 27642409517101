import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard.service';
import { ShellComponent } from './shell/shell.component';
import { GeneralErrorComponent } from './common/components/general-error/general-error.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'generalError/:errorCode', component: GeneralErrorComponent
  },
  {
    path: 'admin',
    component: ShellComponent,
    children: [
      {
        path: 'roleManagement',
        loadChildren: () =>
          import('./pages/role-management/role-management.module').then(
            (m) => m.RoleManagementModule
          ),
      },
      {
        path: 'userManagement',
        loadChildren: () =>
          import('./pages/user-management/user-management.module').then(
            (m) => m.UserManagementModule
          ),
      },
      {
        path: 'circularMemo',
        loadChildren: () =>
          import('./pages/circular-memo/circular-memo.module').then(
            (m) => m.CircularMemoModule
          ),
      },
      {
        path: 'instruction',
        loadChildren: () =>
        import('./pages/instruction/instruction.module').then(
          (m) => m.InstructionModule
        )
      },
      {
        path: 'alertGroups',
        loadChildren: () =>
        import('./pages/alert-groups/alert-groups.module').then(
          (m) => m.AlertGroupsModule
        )
      },
      {
        path: 'inspectionSetting',
        loadChildren: () =>
        import('./pages/inspection/inspection-setting/inspection-setting.module').then(
          (m) => m.InspectionSettingModule
        )
      },
      {
        path: 'inspectionRecord',
        loadChildren: () =>
        import('./pages/inspection/inspection-record/inspection-record.module').then(
          (m) => m.InspectionRecordModule
        )
      },
      {
        path: 'point',
        loadChildren: () =>
        import('./pages/inspection/point-management/point.module').then(
          (m) => m.PointModule
        )
      },
      {
        path: 'inspectionChecklist',
        loadChildren: () =>
        import('./pages/inspection/inspection-checklist/inspection-checklist.module').then(
          (m) => m.InspectionChecklistModule
        )
      },
      {
        path: 'dashboard',
        loadChildren: () =>
        import('./pages/dashboard/dashboard.module').then(
          (m) => m.DashboardModule
        )
      },
      {
        path: 'systemMessage',
        loadChildren: () =>
        import('./pages/system-message/system-message.module').then(
          (m) => m.SystemMessageModule
        )
      },
      {
        path: 'deviceManagement',
        loadChildren: () =>
        import ('./pages/device-management/device-management.module').then(
          (m) => m.DeviceManagementModule
        )
      },
      {
        path: 'systemParameters',
        loadChildren: () =>
        import ('./pages/system-parameters/system-parameters.module').then(
          (m) => m.SystemParametersModule
        )
      },
      {
        path: 'CodeDescriptions',
        loadChildren: () =>
        import ('./pages/code-descriptions/code-descriptions.module').then(
          (m) => m.CodeDescriptionsModule
        )
      },
      {
        path: 'defectReport',
        loadChildren: () =>
        import ('./pages/defect-report/defect-report.module').then(
          (m) => m.DefectReportModule
        )
      },
      {
        path: 'moBuildings',
        loadChildren: () =>
        import ('./pages/mo-buildings/mo-buildings.module').then(
          (m) => m.MoBuildingsModule
        )
      },
      {
        path: 'ePatrolSettings',
        loadChildren: () =>
        import ('./pages/ePatrol-settings/ePatrol-settings.module').then(
          (m) => m.EpatrolSettingsModule
        )
      },
      {
        path: 'downloadReport',
        loadChildren: () =>
        import ('./pages/download-report/download-report.module').then(
          (m) => m.DownloadReportModule
        )
      },
      {
        path: 'patrolRecords',
        loadChildren: () =>
        import ('./pages/patrol-records/patrol-records.module').then(
          (m) => m.PatrolRecordsModule
        )
      },
      {
        path: 'publicHoliday',
        loadChildren: () =>
        import ('./pages/public-holiday-setting/public-holiday-setting.module').then(
          (m) => m.PublicHolidaySettingModule
        )
      },
      {
        path: 'commonDeviceManagement',
        loadChildren: () =>
        import ('./pages/common-device-management/common-device-management.module').then(
          (m) => m.CommonDeviceManagentModule
        )
      },
      {
        path: 'generalError/:errorCode', component: GeneralErrorComponent
      },
      {
        path: '**', component: GeneralErrorComponent
      }
    ],
    canActivateChild: [AuthGuard],
  },
  // {
  //   path: '**', component: GeneralErrorComponent
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
