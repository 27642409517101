<sfui-dialog [isOpen]="dislogParam.isOpen" [isBlocking]="true" maxWidth="90vw" [width]="dislogParam.width">
  <sfui-dialog-header *ngIf="dislogParam.header" [ngClass]="{ 'has-img-header': dislogParam.header.src }">
    <img *ngIf="dislogParam.header.src" src={{dislogParam.header.src}} [class]="dislogParam.header.class" alt="">
    <span *ngIf="dislogParam.header.text">{{ dislogParam.header.text | translate }}</span>
  </sfui-dialog-header>
  <sfui-dialog-content *ngIf="dislogParam.content" [class]="dislogParam.content.class"
    [ngClass]="{'dialog-content-without-header': !dislogParam.header}">
    <span  *ngIf="!dislogParam.content.errorCode" [innerHTML]="dislogParam.content.text | translate"></span>
    <div *ngIf="dislogParam.content.errorCode" class="system-error-wrapper-content" [ngClass]="{'text-center': dislogParam.content.traceId}">{{"COMMON.ERROR_CODE"| translate:{code:dislogParam.content.errorCode} }}
    </div>
    <div *ngIf="dislogParam.content.traceId" class="system-error-wrapper-content text-center">{{"COMMON.TRACKING_ID"| translate}} {{dislogParam.content.traceId}}</div>
    <div *ngIf="dislogParam.content.timestamp" class="system-error-wrapper-content" [ngClass]="{'text-center': dislogParam.content.traceId}">{{dislogParam.content.timestamp | formatDate}}</div>
  </sfui-dialog-content>
  <sfui-dialog-footer>
    <sfui-dialog-button *ngIf="dislogParam.primaryBtn.first" type="primary" [class]="dislogParam.primaryBtn.class"
      (buttonClick)="primaryBtnClick()" [disabled]="dislogParam.primaryBtn.disable"> {{ dislogParam.primaryBtn.text |
      translate }} </sfui-dialog-button>
    <sfui-dialog-button *ngIf="dislogParam.defaultBtn.text" type="default" (buttonClick)="defaultBtnClick()"
      [disabled]="dislogParam.defaultBtn.disable">
      {{ dislogParam.defaultBtn.text | translate }}
    </sfui-dialog-button>
    <sfui-dialog-button *ngIf="!dislogParam.primaryBtn.first" type="primary" [class]="dislogParam.primaryBtn.class"
      (buttonClick)="primaryBtnClick()" [disabled]="dislogParam.primaryBtn.disable"> {{ dislogParam.primaryBtn.text |
      translate }} </sfui-dialog-button>
  </sfui-dialog-footer>
</sfui-dialog>
