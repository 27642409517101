import { Component, Input } from '@angular/core';
import { DialogParam } from '../../service/general-dialog.service';

@Component({
  selector: 'app-general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss'],
})
export class GeneralDialogComponent{
  @Input() dislogParam: DialogParam;

  primaryBtnClick() {
    this.dislogParam.primaryBtn.onClick();
  }

  defaultBtnClick() {
    this.dislogParam.defaultBtn.onClick();
  }
}
