import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface DialogParam {
  width?: number;
  header?: {
    src?: string,
    class?: string,
    text?: string,
  },
  isOpen?: boolean,
  content?: {
    class?: string,
    text: string,
    errorCode?: string,
    traceId?: string,
    timestamp?: number,
  },
  primaryBtn?: {
    first: boolean,
    class?: string,
    text: string,
    onClick?: Function,
    disable?: boolean
  },
  defaultBtn?: {
    text: string,
    onClick?: Function,
    disable?: boolean
  }
}

const DEFAULT_DIALOG_PARAM: DialogParam = {
  header: {
    src: '',
    class: '',
    text: '',
  },
  isOpen: false,
  content: {
    class: '',
    text: '',
  },
  primaryBtn: {
    first: true,
    text: '',
    disable: false
  },
  defaultBtn: {
    text: '',
    disable: false
  },
}

@Injectable({
  providedIn: 'root'
})
export class GeneralDialogService {
  dialogParamSubject = new BehaviorSubject<DialogParam>(DEFAULT_DIALOG_PARAM);
  options: DialogParam;

  private setDialogParam(dialogParam: DialogParam) {
    this.dialogParamSubject.next(dialogParam);
  }

  show(dialogParam: DialogParam) {
    this.options = Object.assign({}, DEFAULT_DIALOG_PARAM, dialogParam);
    this.options.isOpen = true;
    this.setDialogParam(this.options);
  }

  close() {
    this.options.isOpen = false;
    this.setDialogParam(this.options);
  }

  disableBtn(status: boolean) {
    this.options.primaryBtn.disable = status;
    this.options.defaultBtn.disable = status;
    this.setDialogParam(this.options);
  }

  showSimplifiedError(header, body) {
    this.show({
      header: {
        text: header,
      },
      content: {
        class: 'body1-dialog-content',
        text: body,
      },
      primaryBtn: {
        first: true,
        text: 'COMMON.CONFIRM',
        onClick: () => {
          this.close();
        },
      }
    });
  }
}
