import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { NoAuthGuard } from './guards/no-auth.guard';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

const AUTH_MODULE_CONFIG: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [environment.OAUTH_URL],
    sendAccessToken: true,
  },
}

const OAUTH_CONFIG: AuthConfig = {
  clientId: environment.OAUTH_CLIENT_ID,
  issuer: `${environment.OAUTH_URL}`,
  redirectUri: window.location.origin,
  postLogoutRedirectUri: environment.APP_BASE_URL,
  responseType: 'code',
  scope: 'openid',
  sessionChecksEnabled: false,
  showDebugInformation: true,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  silentRefreshShowIFrame: false,
  skipIssuerCheck: true,
  strictDiscoveryDocumentValidation: false,
  silentRefreshTimeout: 2000,
  useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
  clearHashAfterLogin: true, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  useIdTokenHintForSilentRefresh: false,
  timeoutFactor: 1,
  fallbackAccessTokenExpirationTimeInSec: 900,
  oidc: false,
  openUri: (uri) => {
    if (uri.indexOf("/logout") >= 0) {
      window.open(uri, '_blank');
    } else {
      location.href = uri;
    }
  }
}

@NgModule({
  imports: [CommonModule, HttpClientModule, TranslateModule, RouterModule, OAuthModule.forRoot()],
  providers: [
    NoAuthGuard,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: AuthConfig, useValue: OAUTH_CONFIG },
        { provide: OAuthModuleConfig, useValue: AUTH_MODULE_CONFIG },
        { provide: OAuthStorage, useFactory: storageFactory },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
