import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SortedColumn, Pagination, SnackbarType } from 'swire-frontline-ui';
import { HttpOauth } from '../common/service/http-oauth';
import { ErrorCode } from '../common/constants/enums';

export type FilterKey = 'active' | 'all_departments' | 'all_login_method' | 'all_companies';

export interface CurSort {
  sortedColumns?: SortedColumn[];
  pagination?: Pagination;
  filters?: any;
  searchText?: string;
}

export interface SnackbarModal {
  type: SnackbarType;
  message: string;
}

const DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'displayName',
      order: 'asc'
    }
  ],
  pagination:{
    pageSize: 20,
    totalRowCount: 0,
    pageIndex: 0,
  },
  filters: [
    {
      key: 'all_departments',
      selectedKey: 'all_departments',
    },
    {
      key: 'active',
      selectedKey: 'active',
    }
  ],
  searchText: ''
}

@Injectable()
export class UserManagementService {
  serverPath = environment.FWA_URL;
  constructor(private httpOauth: HttpOauth) {}

  snackbar: SnackbarModal = {
    type: 'success',
    message: ''
  };

  curSort: CurSort = DEFAULT_FILTER_SORT;

  initUserSort(){
    this.curSort = DEFAULT_FILTER_SORT;
  }

  getUserManagementList(searchBody): Observable<any> {
    const url = `${this.serverPath}/user/paging/all`;
    const params = { params:searchBody };
    return this.httpOauth.get(url, {}, false, params).pipe(
      map((res) => {
        this.isShowChangeStatusBtn(res.content);
        return res;
      })
    );
  }

  exportUser(params): Observable<any> {
    const url = `${this.serverPath}/report/user/profile/report`;
    return this.httpOauth.post(url, params, {}, false, { responseType: 'blob' }).pipe(
      map((res: any) => {
        this.httpOauth.downloadFile(res, `${params.downloadName}.${params.exportFormat}`);
      })
    );
  }

  isShowChangeStatusBtn(arr: any[]) {
    for (let item of arr) {
      if (item.active) {
        item['showChangeStatusBtn'] = true;
        continue;
      }

      if (item.internal) {
        this.setItemInfoByInternal(item);
      } else {
        if (item.externalUser) {
          item['showChangeStatusBtn'] = item.externalUser.active;
        } else {
          item['showChangeStatusBtn'] = true;
        }
      }
    }
  }

  setItemInfoByInternal(item: any) {
    if (item.azureStatus) {
      if (item.externalUser) {
        item['showChangeStatusBtn'] = item.externalUser.active;
      } else {
        item['showChangeStatusBtn'] = true;
      }
    } else {
      item['showChangeStatusBtn'] = false;
    }
  }

  getCompany(): Observable<any> {
    const url = `${this.serverPath}/user/all/company`;
    return this.httpOauth.get(url);
  }

  getDepartment(): Observable<any> {
    const url = `${this.serverPath}/param/department/all`;
    return this.httpOauth.get(url);
  }

  getCompanyAndDepartment(): Observable<any> {
    const url = `${this.serverPath}/user/company/department/all`;
    return this.httpOauth.get(url);
  }

  getUserDetailById(UserId): Observable<any> {
    const url = `${this.serverPath}/user/${UserId}`;
    return this.httpOauth.get(url);
  }

  getUserRoleAccessControlByUserId(UserId): Observable<Promise<any>> {
    const url = `${this.serverPath}/user/${UserId}/userRole/accessControl`
    return this.httpOauth.get(url).pipe(map(async res=>{
      await this.externalOrADActive(res);
      return res;
    }));
  }

  async externalOrADActive(userDetail) {
    if (userDetail.externalUser && userDetail.externalUser.active === false) {
      userDetail.externalOrADActive = false;
      return;
    }
    if (userDetail.internal && userDetail.loginName) {
      try {
        const res = await this.searchUser(userDetail.loginName).toPromise();
        userDetail.externalOrADActive = !!res?.active;
        return;
      } catch (e) {
        // enable active switch when Error Code = ADUserDoesNotExist
        userDetail.externalOrADActive = e.error.code === ErrorCode.ADUserDoesNotExist;
        userDetail.isADInactive = e.error.code === ErrorCode.ADUserDoesNotExist;
        return;
      }
    }
    userDetail.externalOrADActive = true;
  }

  deactivateUser(UserId): Observable<any> {
    const url = `${this.serverPath}/user/${UserId}/deactivate`;
    return this.httpOauth.post(url);
  }

  activateUser(UserId): Observable<any> {
    const url = `${this.serverPath}/user/${UserId}/activate`;
    return this.httpOauth.post(url);
  }

  getAccessControl(): Observable<any> {
    const url = `${this.serverPath}/rbac/accessControl/all`;
    return this.httpOauth.get(url);
  }

  getAccessControlGroup(): Observable<any>{
    const url = `${this.serverPath}/rbac/accessControl/accessControlGroup/all`;
    return this.httpOauth.get(url);
  }

  checkDuplicateCode(roleCode): Observable<any>{
    const url = `${this.serverPath}/rbac/role/duplicateCheckByCode?code=${roleCode}`;
    return this.httpOauth.get(url);
  }

  getUser(name): Observable<any>{
    const url = `${this.serverPath}/azure/user?displayName=${name}`;
    return this.httpOauth.get(url);
  }

  getRoleList(): any {
    const url = `${this.serverPath}/rbac/role/paging/all`;
    return this.httpOauth.get(url).pipe(
      map(item => {
        if (item) {
          item.content.forEach(element => {
            element.key = element.id;
            element.disabled = !element.active;
            element.label = element.roleCode;
            element.value = element.id;
            element.sidenote = element.disabled ? 'Inactive': undefined;
          });

        }
        return item;
      })
    );
  }

  updateUser(user): Observable<any>{
    const url = `${this.serverPath}/user/${user.id}`;
    return this.httpOauth.put(url,user);
  }

  creactUser(user): Observable<any>{
    const url = `${this.serverPath}/user`;
    return this.httpOauth.post(url,user);
  }

  searchUser(name: string): Observable<any> {
    const url = `${this.serverPath}/azure/searchUser`;
    return this.httpOauth.get(url, {}, false, { params: { upn: name } });
  }

  createExternalUser(user): Observable<any>{
    const url = `${this.serverPath}/user/external`;
    return this.httpOauth.post(url,user);
  }

  getExternalUsers(params): Observable<any>{
    const url = `${this.serverPath}/user/external`;
    return this.httpOauth.get(url, {}, false, { params }).pipe(
      map(item => {
        if (item) {
          item.content.forEach(element => {
            element.key = element.id;
            element.disabled = !element.active;
            element.label = element.displayName;
          });

        }
        return item;
      })
    );;
  }

  margeNonAdUser(userId, userPrincipalName) {
    const url = `${this.serverPath}/user/${userId}/merge`;
    return this.httpOauth.post(url,{userPrincipalName});
  }

  unlinkUser(UserId): Observable<any> {
    const url = `${this.serverPath}/user/${UserId}/unlink`;
    return this.httpOauth.get(url);
  }
}
