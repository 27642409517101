import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { SortedColumn, Pagination } from 'swire-frontline-ui';
import { HttpOauth } from '../common/service/http-oauth';

export type FilterKey = 'all_types' | 'all_modules' | 'active';

export interface CurSort {
  sortedColumns?: SortedColumn[];
  pagination?: Pagination;
  searchText?: string;
}

const DEFAULT_FILTER_SORT: CurSort = {
  sortedColumns: [
    {
      columnKey: 'id',
      order: 'asc'
    }
  ],
  pagination:{
    pageSize: 20,
    totalRowCount: 0,
    pageIndex: 0,
  },
  searchText: ''
}

@Injectable({
  providedIn: 'root',
})
export class SystemParameterService {
  serverPath = environment.FWA_URL;
  isLoading = false;
  curSort: CurSort = DEFAULT_FILTER_SORT;

  constructor(private httpOauth: HttpOauth) {}

  initParameterSort(){
    this.curSort = DEFAULT_FILTER_SORT;
  }

  getSystemParameterList(searchBody): Observable<any> {
    const url = `${this.serverPath}/param/paging/all`;
    const params = { params: searchBody };
    return this.httpOauth.get(url, {}, false, params);
  }

  getSystemParameterById(id): Observable<any> {
    const url = `${this.serverPath}/param/${id}`;
    return this.httpOauth.get(url);
  }

  createSystemParameter(paramDetails): Observable<any> {
    const url = `${this.serverPath}/param`;
    return this.httpOauth.post(url, paramDetails);
  }

  updateSystemParameter(id, paramDetails): Observable<any> {
    const url = `${this.serverPath}/param/${id}`;
    return this.httpOauth.put(url, paramDetails);
  }

  getSystemParameterByCode(code): Observable<any> {
    const url = `${this.serverPath}/param`;
    const params = { params: { code: code } };
    return this.httpOauth.get(url, {}, false, params);
  }

}
