import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnInit {
  initials: string = '';

  @Input() displayName: string = '';
  @Input() title: string = '';
  @Input() department: string = '';
  @Input() email: string = '';
  @Input() width: string = '0px';
  @Input() isShowRightBorder: boolean = false;
  @Input() disable: boolean = false;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    const reg26 = /^[a-zA-Z]$/;
    let initials = '';
    const nameArr = this.displayName.split(" ");
    nameArr.forEach((ele, index) => {
      if (index < 3) {
        const initial = ele.slice(0, 1);
        if (reg26.test(initial)) {
          initials += initial
        }
      }
    });
    this.initials = initials;
  }
}
